import React, { memo, ImgHTMLAttributes } from 'react';

const CustomImage = ({ src, alt, ...rest }: ImgHTMLAttributes<HTMLImageElement>): JSX.Element => {
  return (
    <img
      loading="lazy"
      src={src && src !== '' ? src : 'https://artoon-imentor-data-dev.s3.amazonaws.com/iMentor/iMentor_Placeholder.png'}
      alt={alt}
      onError={(e: any) => (e.target.src = process.env.REACT_APP_IMAGE_URL)}
      {...rest}
    />
  );
};

export default memo(CustomImage);
