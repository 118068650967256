import { optionsProps, PaginationType } from '../../../types';
import { create } from 'zustand';

interface Sort {
  headerType: string;
  sortType: string;
}

interface SocketLoaders {
  join: boolean;
  getMsgList: boolean;
  sendMsg: boolean;
  getMsg: boolean;
  getNotifications: boolean;
  chatLoader: boolean;
}

interface useSocketMessageStore {
  socketData: any;
  socketConnected: boolean;
  socketLoaders: SocketLoaders;
  socket: {};
  connectionList: any[];
  receiverDetail: any | null;
  messageList: {};
  chatId: string;
  imageUploadLoader: boolean;
  getNotificationCount: number;
  notificationResponse: any | null;
  isRecording: boolean;
  imageMessageData: {};
  replyBackMessage: any | null;
  pagination: PaginationType;
  setPagination(pagination: PaginationType): void;
  setState(nextState: Partial<useSocketMessageStore>): void;
}
export const initinalSokectValues = {
  socketData: {},
  socketConnected: false,
  socketLoaders: {
    join: false,
    getMsgList: false,
    sendMsg: false,
    getMsg: false,
    getNotifications: false,
    chatLoader: false,
  },
  socket: {},
  connectionList: [],
  receiverDetail: null,
  messageList: {},
  chatId: '',
  imageUploadLoader: false,
  getNotificationCount: 0,
  notificationResponse: null,
  isRecording: false,
  imageMessageData: {},
  replyBackMessage: null,
  pagination: {
    limit: 50,
    page: 1,
    total: 0,
    pages: 0,
  },
};

const useSocketStore = create<any>((set: any, get: any) => ({
  ...initinalSokectValues,
  getState: () => get(),
  setPagination: (pagination: PaginationType) => set(() => ({ pagination })),
  setState: (nextState: any) => set(() => ({ ...nextState })),
}));

export default useSocketStore;
