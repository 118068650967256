import { ReactNode } from 'react';
import { CSSTransition, TransitionGroup, SwitchTransition } from 'react-transition-group';

interface ReactTransitionProps {
  children: ReactNode;
  flag?: boolean;
  timeout?: number;
  classNames?: string;
  key?: string;
}

interface ReactTransitionGroupProps {
  children: ReactNode;
  className?: string;
  element?: string;
}

const ReactTransitionGroup = ({ children, className = 'transition', element = 'div' }: any) => {
  return (
    <TransitionGroup className={className} component={element}>
      {children}
    </TransitionGroup>
  );
};

const ReactTransition = ({
  children,
  flag = true,
  timeout = 500,
  classNames = 'transition',
  key = '',
}: ReactTransitionProps) => {
  return (
    <CSSTransition in={flag} timeout={timeout} unmountOnExit classNames={classNames} key={key}>
      {children}
    </CSSTransition>
  );
};

const SwitchTransitionComp = ({ children }: { children: ReactNode }) => {
  return (
    <SwitchTransition in-out>
      <ReactTransition>{children}</ReactTransition>
    </SwitchTransition>
  );
};

export { ReactTransitionGroup, ReactTransition, SwitchTransitionComp };
