export const main_bg = process.env.PUBLIC_URL + '/images/main_bg.svg';
export const headerLogo = process.env.PUBLIC_URL + '/images/imentor_white_logo.svg';
export const loginManagmentView = process.env.PUBLIC_URL + '/images/login_managment_view.png';
export const Logo = process.env.PUBLIC_URL + '/images/Logo.svg';
export const Avatar = process.env.PUBLIC_URL + '/images/Avatar.png';
export const Photo = process.env.PUBLIC_URL + '/images/Photo.png';
export const esther = process.env.PUBLIC_URL + '/images/esther.png';
export const leslie = process.env.PUBLIC_URL + '/images/leslie.png';
export const cameron = process.env.PUBLIC_URL + '/images/leslie.png';
export const amanda = process.env.PUBLIC_URL + '/images/leslie.png';
export const filter = process.env.PUBLIC_URL + '/images/filter_icon.svg';
export const serch = process.env.PUBLIC_URL + '/images/search_icon.svg';
export const plus_btn = process.env.PUBLIC_URL + '/images/plus_icon.svg';
export const upload_icon = process.env.PUBLIC_URL + '/images/upload.svg';
export const down_arrow = process.env.PUBLIC_URL + '/images/down_arrow.svg';
export const option_icon = process.env.PUBLIC_URL + '/images/option_icon.svg';
export const option_menu = process.env.PUBLIC_URL + '/images/option_menu.svg';
export const dustbin = process.env.PUBLIC_URL + '/images/dustbin.svg';
export const close_icon = process.env.PUBLIC_URL + '/images/Close.svg';
export const remove = process.env.PUBLIC_URL + '/images/Remove.svg';
export const info = process.env.PUBLIC_URL + '/images/Info.svg';
export const accept_circle = process.env.PUBLIC_URL + '/images/accept-circle.svg';
export const trash = process.env.PUBLIC_URL + '/images/trash.svg';
export const arrow = process.env.PUBLIC_URL + '/images/Arrow.svg';
export const imentor_logo = process.env.PUBLIC_URL + '/images/Imentor_logo.png';
export const red_close = process.env.PUBLIC_URL + '/images/red_close.svg';
export const cross_close = process.env.PUBLIC_URL + '/images/cross_close.svg';
export const dark_blue_close_btn = process.env.PUBLIC_URL + '/images/dark_blue_close_btn.svg';
export const Maximize_decide = process.env.PUBLIC_URL + '/images/Maximize_decide.svg';
export const black_info = process.env.PUBLIC_URL + '/images/black_info.svg';
export const Location = process.env.PUBLIC_URL + '/images/Location.svg';
export const plus_blue = process.env.PUBLIC_URL + '/images/Plus.svg';
export const avtar_img = process.env.PUBLIC_URL + '/images/User_fill.svg';
export const black_plus = process.env.PUBLIC_URL + '/images/black_plus.svg';
export const down_arrow_number = process.env.PUBLIC_URL + '/images/down_arrow_number.svg';
export const edit_profile_img = process.env.PUBLIC_URL + '/images/edit_profile.jpg';
export const Success_Checkmark = process.env.PUBLIC_URL + '/images/Success_Checkmark.svg';
export const white_trash = process.env.PUBLIC_URL + '/images/white_trash.svg';
export const Plain = process.env.PUBLIC_URL + '/images/Plain.svg';
export const institution = process.env.PUBLIC_URL + '/images/institution.svg';
export const Error = process.env.PUBLIC_URL + '/images/Error.svg';
export const Avatar_icon = process.env.PUBLIC_URL + '/images/Avatar.svg';
export const white_Arrow = process.env.PUBLIC_URL + '/images/white_Arrow.svg';
export const user_pic = process.env.PUBLIC_URL + '/images/user_pic.png';
export const blue_plus_icon = process.env.PUBLIC_URL + '/images/blue_plus_icon.svg';
export const border_close_box = process.env.PUBLIC_URL + '/images/border_close_box.svg';
export const resend_btn_arrow = process.env.PUBLIC_URL + '/images/resend_btn_arrow.svg';
export const dot_edit_icon = process.env.PUBLIC_URL + '/images/dot_edit_icon.svg';
export const dot_login_icon = process.env.PUBLIC_URL + '/images/dot_login_icon.svg';
export const dot_resent_icon = process.env.PUBLIC_URL + '/images/dot_resent_icon.svg';
export const Delete = process.env.PUBLIC_URL + '/images/Delete.svg';
export const attachment = process.env.PUBLIC_URL + '/images/attachment.svg';
export const camera = process.env.PUBLIC_URL + '/images/camera.svg';
export const tab_remove_icon = process.env.PUBLIC_URL + '/images/tab_remove_icon.svg';
export const black_Pairs = process.env.PUBLIC_URL + '/images/black_Pairs.svg';
export const blue_Pairs = process.env.PUBLIC_URL + '/images/blue_Pairs.svg';
export const gray_pairs = process.env.PUBLIC_URL + '/images/gray_pairs.svg';
export const gray_student = process.env.PUBLIC_URL + '/images/gray_student.svg';
export const blue_student = process.env.PUBLIC_URL + '/images/blue_student.svg';
export const Archived = process.env.PUBLIC_URL + '/images/Archived.svg';
export const Unselect = process.env.PUBLIC_URL + '/images/Unselect.svg';
export const black_student = process.env.PUBLIC_URL + '/images/black_student.svg';
export const black_teacher = process.env.PUBLIC_URL + '/images/black_Teacher.svg';
export const black_Plain = process.env.PUBLIC_URL + '/images/black_Plain.svg';
export const Maximize_full = process.env.PUBLIC_URL + '/images/Maximize_full.svg';
export const edit_img = process.env.PUBLIC_URL + '/images/Edit_Img.svg';
export const Input_Remove = process.env.PUBLIC_URL + '/images/Input_Remove.svg';
export const Dropdown_Arrow = process.env.PUBLIC_URL + '/images/Dropdown_Arrow.svg';
export const Blue_Upload = process.env.PUBLIC_URL + '/images/Blue_Upload.svg';
export const active_tab_icon = process.env.PUBLIC_URL + '/images/active_tab_icon.svg';
export const tab_icon = process.env.PUBLIC_URL + '/images/tab_icon.svg';
export const mentor_tab_arrow = process.env.PUBLIC_URL + '/images/mentor_tab_arrow.svg';
export const Save_Draft = process.env.PUBLIC_URL + '/images/Save_Draft.svg';
export const Remove_Mentees = process.env.PUBLIC_URL + '/images/Remove_Mentees.svg';
export const help_icon = process.env.PUBLIC_URL + '/images/help_icon.svg';
export const Accept_circle = process.env.PUBLIC_URL + '/images/Accept_circle.svg';
export const Right_Arrow_White = process.env.PUBLIC_URL + '/images/Right_Arrow_White.svg';
export const Left_Arrow_White = process.env.PUBLIC_URL + '/images/Left_Arrow_White.svg';
export const Armenia_flag = process.env.PUBLIC_URL + '/images/Armenia_flag.svg';
export const Germany_flag = process.env.PUBLIC_URL + '/images/Germany_flag.svg';
export const Pagination_arrow = process.env.PUBLIC_URL + '/images/Pagination_arrow.svg';
export const Pagination_arrow_right = process.env.PUBLIC_URL + '/images/Pagination_arrow_right.svg';
export const big_Success_Checkmark = process.env.PUBLIC_URL + '/images/big_Success_Checkmark.svg';
export const invitation_badge = process.env.PUBLIC_URL + '/images/invitation_badge.svg';
export const send_message = process.env.PUBLIC_URL + '/images/send_message.svg';
export const Edit = process.env.PUBLIC_URL + '/images/Edit.svg';
export const Teacher = process.env.PUBLIC_URL + '/images/Teacher.svg';
export const yes = process.env.PUBLIC_URL + '/images/Yes.png';
export const No = process.env.PUBLIC_URL + '/images/No.svg';
export const Arrow_blue = process.env.PUBLIC_URL + '/images/Arrow_blue.svg';
export const Settings = process.env.PUBLIC_URL + '/images/Settings.svg';
export const Logout = process.env.PUBLIC_URL + '/images/Logout.svg';
export const institution_icon = process.env.PUBLIC_URL + '/images/institution_icon.svg';
export const imentor_logo_login = process.env.PUBLIC_URL + '/images/imentor_logo_login.svg';
export const login_as_profile = process.env.PUBLIC_URL + '/images/login_as_profile.jpg';
export const Switch = process.env.PUBLIC_URL + '/images/Switch.svg';
export const delete_icon = process.env.PUBLIC_URL + '/images/delete_icon.svg';

export const edit_partner = process.env.PUBLIC_URL + '/images/edit_partner.svg';
export const edit_partner_icon = process.env.PUBLIC_URL + '/images/edit_partner_icon.svg';
export const input_fild_remove = process.env.PUBLIC_URL + '/images/input_fild_remove.svg';
export const country_select_edit_icon = process.env.PUBLIC_URL + '/images/country_select_edit_icon.svg';
export const Alert_icon = process.env.PUBLIC_URL + '/images/Alert_icon.svg';
export const unassign_close_icon = process.env.PUBLIC_URL + '/images/unassign_close_icon.svg';
export const Polygon = process.env.PUBLIC_URL + '/images/Polygon.svg';
export const m_Success_Checkmark = process.env.PUBLIC_URL + '/images/m_Success_Checkmark.svg';
export const line = process.env.PUBLIC_URL + '/images/Line1.svg';
export const blue_teacher = process.env.PUBLIC_URL + '/images/blue_teacher.svg';
export const white_Unselect = process.env.PUBLIC_URL + '/images/white_Unselect.svg';
export const join_date_icon = process.env.PUBLIC_URL + '/images/join_date_icon.svg';
export const warning_icon = process.env.PUBLIC_URL + '/images/warning_icon.svg';
export const black_Archived = process.env.PUBLIC_URL + '/images/black_Archived.svg';
export const yellow_error = process.env.PUBLIC_URL + '/images/yellow_error.svg';
export const small_avatar = process.env.PUBLIC_URL + '/images/small_avatar.svg';
export const Remove_icon = process.env.PUBLIC_URL + '/images/Remove_icon.svg';
export const fill_remove = process.env.PUBLIC_URL + '/images/fill_remove.svg';
export const small_Logo = process.env.PUBLIC_URL + '/images/small_Logo.svg';
export const Edit_disable = process.env.PUBLIC_URL + '/images/Edit_disable.svg';
export const blue_Camera = process.env.PUBLIC_URL + '/images/blue_Camera.svg';
export const blue_Microphone = process.env.PUBLIC_URL + '/images/blue_Microphone.svg';
export const blue_Plain = process.env.PUBLIC_URL + '/images/blue_Plain.svg';
export const back_arrow_icon = process.env.PUBLIC_URL + '/images/back_arrow_icon.svg';
export const resend_invited_icon = process.env.PUBLIC_URL + '/images/resend_invited_icon.svg';
export const blue_Info = process.env.PUBLIC_URL + '/images/black_info.svg';
export const active_pairs = process.env.PUBLIC_URL + '/images/active_pairs.svg';
export const grey_pairs = process.env.PUBLIC_URL + '/images/grey_pairs.svg';
export const active_student = process.env.PUBLIC_URL + '/images/active_student.svg';
export const grey_student = process.env.PUBLIC_URL + '/images/grey_student.svg';
export const dark_info = process.env.PUBLIC_URL + '/images/dark_info.svg';
export const up_Arrow = process.env.PUBLIC_URL + '/images/up_Arrow.svg';
export const Line_Arrow_Up = process.env.PUBLIC_URL + '/images/Line_Arrow_Up.svg';
export const Line_Down_Arrow = process.env.PUBLIC_URL + '/images/Line_Down_Arrow.svg';
export const chat = process.env.PUBLIC_URL + '/images/Chat.svg';
export const Menu_Dots = process.env.PUBLIC_URL + '/images/Menu Dots.svg';
export const Drag_dots = process.env.PUBLIC_URL + '/images/Drag dots.svg';
export const top_arrow = process.env.PUBLIC_URL + '/images/top_arrow.svg';
export const progress_clock_icon = process.env.PUBLIC_URL + '/images/progress_clock_icon.svg';
export const dot_replace_edit_btn = process.env.PUBLIC_URL + '/images/dot_replace_edit_btn.svg';
export const blue_Drag_dots = process.env.PUBLIC_URL + '/images/blue_Drag_dots.svg';
export const SaveDraft = process.env.PUBLIC_URL + '/images/SaveDraft.svg';
export const blue_info_pop = process.env.PUBLIC_URL + '/images/blue_info_pop.svg';
export const pdf_icon = process.env.PUBLIC_URL + '/images/pdf_icon.svg';
export const suggetion_remove = process.env.PUBLIC_URL + '/images/suggetion_remove.svg';
export const filter_drop_down_up_arrow = process.env.PUBLIC_URL + '/images/filter_drop_down_up_arrow.svg';
export const dark_arrow = process.env.PUBLIC_URL + '/images/dark_arrow.svg';
export const mail_icon = process.env.PUBLIC_URL + '/images/mail_icon.svg';
export const tick_circle_mail = process.env.PUBLIC_URL + '/images/tick_circle_mail.svg';
export const info_blue = process.env.PUBLIC_URL + '/images/info_blue.svg';
export const border_trash = process.env.PUBLIC_URL + '/images/border_trash.svg';
export const border_Plain = process.env.PUBLIC_URL + '/images/border_Plain.svg';
export const upload_csv_icon = process.env.PUBLIC_URL + '/images/upload_csv_icon.svg';
export const gridicons_visible = process.env.PUBLIC_URL + '/images/gridicons_visible.svg';
export const gridicons_not_visible = process.env.PUBLIC_URL + '/images/gridicons_not_visible.svg';
export const personal_pic = process.env.PUBLIC_URL + '/images/personal_pic.svg';
export const lock_info = process.env.PUBLIC_URL + '/images/lock_info.svg';
export const img_1 = process.env.PUBLIC_URL + '/images/img_1.png';
export const img_2 = process.env.PUBLIC_URL + '/images/img_2.png';
export const img_3 = process.env.PUBLIC_URL + '/images/img_3.png';
export const img_4 = process.env.PUBLIC_URL + '/images/img_4.png';
export const img_5 = process.env.PUBLIC_URL + '/images/img_5.png';
export const img_6 = process.env.PUBLIC_URL + '/images/img_6.png';
export const img_7 = process.env.PUBLIC_URL + '/images/img_7.png';
export const img_8 = process.env.PUBLIC_URL + '/images/img_8.png';
export const img_9 = process.env.PUBLIC_URL + '/images/img_9.png';
export const file_doc = process.env.PUBLIC_URL + '/images/file_doc.svg';
export const file_pdf = process.env.PUBLIC_URL + '/images/file_pdf.svg';
export const download = process.env.PUBLIC_URL + '/images/download.svg';
export const serch_list_remove_icon = process.env.PUBLIC_URL + '/images/serch_list_remove_icon.svg';
export const error_warning_icon = process.env.PUBLIC_URL + '/images/error_warning_icon.svg';
export const unauthoraiz_icon = process.env.PUBLIC_URL + '/images/unauthoraiz_icon.svg';
export const events_1 = process.env.PUBLIC_URL + '/images/events_1.png';
export const events_2 = process.env.PUBLIC_URL + '/images/events_2.png';
export const events_3 = process.env.PUBLIC_URL + '/images/events_3.png';
export const events_4 = process.env.PUBLIC_URL + '/images/events_4.png';
export const events_5 = process.env.PUBLIC_URL + '/images/events_5.png';
export const map_point = process.env.PUBLIC_URL + '/images/map_point.svg';
export const loader = process.env.PUBLIC_URL + '/images/loader.png';
export const setting_drop_arrow = process.env.PUBLIC_URL + '/images/setting_drop_arrow.svg';
export const unauthoraiz_bg = process.env.PUBLIC_URL + '/images/unauthoraiz_bg.png';
export const msg_error = process.env.PUBLIC_URL + '/images/msg_error.svg';
export const smile_ellipse = process.env.PUBLIC_URL + '/images/smile_ellipse.svg';
export const pairs_arrow = process.env.PUBLIC_URL + '/images/pairs_arrow.svg';
export const chat_sad = process.env.PUBLIC_URL + '/images/chat_sad.svg';
export const union_gray = process.env.PUBLIC_URL + '/images/union_gray.svg';
export const union_gray_bg = process.env.PUBLIC_URL + '/images/union_gray_bg.svg';
export const pair_archive = process.env.PUBLIC_URL + '/images/pair_archive.svg';
export const group_member_icon = process.env.PUBLIC_URL + '/images/group_member_icon.svg';
export const right_arrow_gray = process.env.PUBLIC_URL + '/images/right_arrow_gray.svg';
export const left_arrow_gray = process.env.PUBLIC_URL + '/images/left_arrow_gray.svg';
export const project_img = process.env.PUBLIC_URL + '/images/project_img.png';
export const eye_off = process.env.PUBLIC_URL + '/images/eye_off.svg';
export const skip_warning_icon = process.env.PUBLIC_URL + '/images/skip_warning_icon.svg';
export const skip_next_page = process.env.PUBLIC_URL + '/images/skip_next_page.svg';
export const groups = process.env.PUBLIC_URL + '/images/groups.svg';
export const profile = process.env.PUBLIC_URL + '/images/profile.jpg';
export const RecomendedTo = process.env.PUBLIC_URL + '/images/RecomendedTo.svg';
export const video_img = process.env.PUBLIC_URL + '/images/video_img.jpg';
export const groups_icon = process.env.PUBLIC_URL + '/images/groups_icon.svg';
export const youtube = process.env.PUBLIC_URL + '/images/youtube.png';
export const gray_Remove = process.env.PUBLIC_URL + '/images/gray_Remove.svg';
export const material_symbols = process.env.PUBLIC_URL + '/images/material-symbols_select-all.svg';
export const events_bg = process.env.PUBLIC_URL + '/images/events_bg.png';
export const calendar_tick = process.env.PUBLIC_URL + '/images/calendar_tick.svg';
export const Frame = process.env.PUBLIC_URL + '/images/Frame.svg';
export const note_text = process.env.PUBLIC_URL + '/images/note_text.svg';
export const mlink_icon = process.env.PUBLIC_URL + '/images/mlink_icon.svg';
export const event_details = process.env.PUBLIC_URL + '/images/event_details.png';
export const ellipsis = process.env.PUBLIC_URL + '/images/ellipsis.svg';
export const events_calender = process.env.PUBLIC_URL + '/images/events_calender.svg';
export const events_clock = process.env.PUBLIC_URL + '/images/events_clock.svg';
export const google_meet = process.env.PUBLIC_URL + '/images/google_meet.svg';
export const close_circle = process.env.PUBLIC_URL + '/images/close_circle.svg';
export const guest_remove = process.env.PUBLIC_URL + '/images/guest_remove.svg';

export const Search_msg = process.env.PUBLIC_URL + '/images/search_msg.svg';
export const Ellipse6 = process.env.PUBLIC_URL + '/images/Ellipse6.png';
export const Charm_tickdouble = process.env.PUBLIC_URL + '/images/charm_tick_double.svg';
export const Group_pic = process.env.PUBLIC_URL + '/images/group_pic.png';
export const Chat_icon = process.env.PUBLIC_URL + '/images/chat_icon.png';
export const Profile_img = process.env.PUBLIC_URL + '/images/profile_img.png';
export const Microphone = process.env.PUBLIC_URL + '/images/Microphone.svg';
export const Plain2 = process.env.PUBLIC_URL + '/images/Plain2.svg';
export const PdfIcon = process.env.PUBLIC_URL + '/images/pdf_icon.svg';
export const Powerpoint = process.env.PUBLIC_URL + '/images/powerpoint.svg';
export const Excel = process.env.PUBLIC_URL + '/images/excel.svg';
export const Word = process.env.PUBLIC_URL + '/images/word.svg';
export const Project_icon = process.env.PUBLIC_URL + '/images/project_icon.svg';
export const Team_img1 = process.env.PUBLIC_URL + '/images/team_img1.svg';
export const Team_img2 = process.env.PUBLIC_URL + '/images/team_img2.svg';
export const Team_img3 = process.env.PUBLIC_URL + '/images/team_img3.svg';
export const pop_close = process.env.PUBLIC_URL + '/images/pop_close.svg';
export const Camera_icon = process.env.PUBLIC_URL + '/images/Camera_icon.svg';
export const resend_invited_icon_desable = process.env.PUBLIC_URL + '/images/resend_invited_icon_desable.svg';
export const danger = process.env.PUBLIC_URL + '/images/danger.svg';
export const skip_modal_last_icon = process.env.PUBLIC_URL + '/images/skip_modal_last_icon.svg';
export const gray_tick_double = process.env.PUBLIC_URL + '/images/gray_tick_double.svg';
export const Polygon_calendar = process.env.PUBLIC_URL + '/images/Polygon_calendar.svg';
export const calender_rec = process.env.PUBLIC_URL + '/images/calender_rec.png';
export const re_active_icone = process.env.PUBLIC_URL + '/images/re_active_icone.svg';
export const url_link_icon = process.env.PUBLIC_URL + '/images/url_link_icon.svg';
export const Round_Alt_Arrow_Left = process.env.PUBLIC_URL + '/images/Round_Alt_Arrow_Left.svg';
export const imentor_white_2 = process.env.PUBLIC_URL + '/images/imentor_white_2.svg';
export const group_details = process.env.PUBLIC_URL + '/images/group_details.svg';
export const Edit_Group = process.env.PUBLIC_URL + '/images/Edit_Group.svg';
export const gray_archived = process.env.PUBLIC_URL + '/images/gray_archived.svg';
export const Waveform = process.env.PUBLIC_URL + '/images/Waveform.svg';
export const VolumeLoud = process.env.PUBLIC_URL + '/images/VolumeLoud.svg';
export const Close_circle_one = process.env.PUBLIC_URL + '/images/Close_circle_one.svg';
export const play_btn = process.env.PUBLIC_URL + '/images/play_btn.svg';
export const pause_btn = process.env.PUBLIC_URL + '/images/pause_btn.svg';
export const pausse_btn_audio = process.env.PUBLIC_URL + '/images/pause_audio.svg';
export const play_audio = process.env.PUBLIC_URL + '/images/play_audio.svg';
export const Blue_Remove = process.env.PUBLIC_URL + '/images/Blue_Remove.svg';
export const linkedin = process.env.PUBLIC_URL + '/images/linkedin.svg';
export const pptFile = process.env.PUBLIC_URL + '/images/pptFile.svg';
export const doubleTick = process.env.PUBLIC_URL + '/images/doubleTick.svg';
export const defaultFile = process.env.PUBLIC_URL + '/images/defaultFile.svg';
export const left_back_arrow = process.env.PUBLIC_URL + '/images/left_back_arrow.svg';
export const Draft = process.env.PUBLIC_URL + '/images/Draft.svg';
export const Matches_Down_Arrow = process.env.PUBLIC_URL + '/images/Matches_Down_Arrow.svg';
export const Matching_Error = process.env.PUBLIC_URL + '/images/Matching_Error.svg';
export const Matches_quesion_arrow = process.env.PUBLIC_URL + '/images/Matches_quesion_arrow.png';
export const Matches_quesion_arrow_up = process.env.PUBLIC_URL + '/images/Matches_quesion_arrow_up.png';
export const pair_list_img = process.env.PUBLIC_URL + '/images/pair_list_img.png';
export const group_list_img = process.env.PUBLIC_URL + '/images/group_list_img.png';
export const infoCircle = process.env.PUBLIC_URL + '/images/info-circle.svg';
export const delete_group_icon = process.env.PUBLIC_URL + '/images/delete_group_icon.svg';
export const click_edit_btn = process.env.PUBLIC_URL + '/images/click_edit_btn.svg';
export const content = process.env.PUBLIC_URL + '/images/content.png';
export const volumeMute = process.env.PUBLIC_URL + '/images/volumeMute.svg';
export const down_aerrow_matching = process.env.PUBLIC_URL + '/images/down_aerrow_matching.svg';
export const details_icon = process.env.PUBLIC_URL + '/images/details_icon.svg';
export const upload_file = process.env.PUBLIC_URL + '/images/UploadFile.svg';
export const csv_icon = process.env.PUBLIC_URL + '/images/Csv_icon.svg';
export const unread_notify = process.env.PUBLIC_URL + '/images/unread_notify.svg';
export const notify_logo = process.env.PUBLIC_URL + '/images/notify_logo.svg';
export const calender_arrow = process.env.PUBLIC_URL + '/images/calender_arrow.svg';
export const category_camera = process.env.PUBLIC_URL + '/images/category_camera.svg';
export const category_books = process.env.PUBLIC_URL + '/images/category_books.svg';
export const category_pdf = process.env.PUBLIC_URL + '/images/category_pdf.svg';
export const category_musicnote = process.env.PUBLIC_URL + '/images/category_musicnote.svg';
export const category_xls = process.env.PUBLIC_URL + '/images/category_xls.svg';
export const note_arrow_left = process.env.PUBLIC_URL + '/images/note-arrow-left.svg';
export const blue_chat = process.env.PUBLIC_URL + '/images/blue_chat.svg';
export const blue_note = process.env.PUBLIC_URL + '/images/blue_note.svg';
export const Blue_Deal = process.env.PUBLIC_URL + '/images/Blue_Deal.svg';
export const edit_note_blk = process.env.PUBLIC_URL + '/images/edit_note_blk.svg';
export const Note_Trash = process.env.PUBLIC_URL + '/images/Note_Trash.svg';
export const clock_icon = process.env.PUBLIC_URL + '/images/clock_icon.svg';
export const danger_delete = process.env.PUBLIC_URL + '/images/danger_delete.svg';
export const upload_white = process.env.PUBLIC_URL + '/images/upload_white.svg';
export const add_note = process.env.PUBLIC_URL + '/images/add_note.svg';

export const contentmusicnote = process.env.PUBLIC_URL + '/images/contentmusicnote.svg';
export const contentcamera = process.env.PUBLIC_URL + '/images/contentcamera.svg';
export const contentFileIconPDF = process.env.PUBLIC_URL + '/images/conptentFileIconPDF.svg';
export const contentFileIconDOC = process.env.PUBLIC_URL + '/images/conptentFileIconDOC.svg';
export const contentFileIconXLS = process.env.PUBLIC_URL + '/images/conptentFileIconXLS.svg';
export const books_small = process.env.PUBLIC_URL + '/images/books_small.svg';
export const small_doc = process.env.PUBLIC_URL + '/images/small_doc.svg';
export const oops_unexcepted_error_icon = process.env.PUBLIC_URL + '/images/oops_unexcepted_error_icon.svg';
export const profile_circle = process.env.PUBLIC_URL + '/images/profile_circle.svg';

export const content_link_icon = process.env.PUBLIC_URL + '/images/content_link_icon.svg';
export const disable_list_icon = process.env.PUBLIC_URL + '/images/disable_list_icon.svg';
export const eye = process.env.PUBLIC_URL + '/images/eye.svg';
export const Archive_Icon = process.env.PUBLIC_URL + '/images/Archive_Icon.svg';
export const FastForward = process.env.PUBLIC_URL + '/images/fastforward.svg';
export const Maximize = process.env.PUBLIC_URL + '/images/maximize.svg';
export const PreviewMute = process.env.PUBLIC_URL + '/images/previewMute.png';
export const Rewind = process.env.PUBLIC_URL + '/images/revind_img.svg';
export const Video_Pause = process.env.PUBLIC_URL + '/images/video_pause.svg';
export const Volume_2 = process.env.PUBLIC_URL + '/images/volume-2.svg';
export const play_circle = process.env.PUBLIC_URL + '/images/play_circle.svg';
export const down_gray_react = process.env.PUBLIC_URL + '/images/down_gray_react.svg';
export const send_mesg_blue = process.env.PUBLIC_URL + '/images/send_mesg_blue.svg';
export const notification_icon = process.env.PUBLIC_URL + '/images/notification_icon.svg';
export const mesg_loader = process.env.PUBLIC_URL + '/images/mesg_loader.svg';
export const events_icon = process.env.PUBLIC_URL + '/images/events_icon.svg';
export const mail_icon_guest = process.env.PUBLIC_URL + '/images/mail.svg';
export const phone_icon = process.env.PUBLIC_URL + '/images/phone.svg';
export const guest_edit_icon = process.env.PUBLIC_URL + '/images/guest_edit_icon.svg';
export const Edit_btn_Icon = process.env.PUBLIC_URL + '/images/Edit_btn_Icon.svg';
export const timer = process.env.PUBLIC_URL + '/images/timer.svg';
export const cloud_downlaod = process.env.PUBLIC_URL + '/images/cloud_downlaod.svg';
export const events_delete = process.env.PUBLIC_URL + '/images/events_delete.svg';
export const calender_card = process.env.PUBLIC_URL + '/images/calender_card.svg';
export const PPT_blue = process.env.PUBLIC_URL + '/images/PPT_blue.svg';
export const PDF_blue = process.env.PUBLIC_URL + '/images/PDF_blue.svg';
export const XLS_blue = process.env.PUBLIC_URL + '/images/XLS_blue.svg';
export const MP3ICON = process.env.PUBLIC_URL + '/images/MP3Icon.svg';
export const uploadupIcon = process.env.PUBLIC_URL + '/images/uploadupIcon.svg';
export const imagePhoto = process.env.PUBLIC_URL + '/images/imagePhoto.svg';
export const eventLibimg = process.env.PUBLIC_URL + '/images/eventLibimg.png';
export const Blue_Content = process.env.PUBLIC_URL + '/images/Blue_Content.svg';
export const Blue_Eventts = process.env.PUBLIC_URL + '/images/Blue_Events.svg';
export const right_arrow = process.env.PUBLIC_URL + '/images/right_arrow.svg';
export const left_arrow = process.env.PUBLIC_URL + '/images/left_arrow.svg';
export const blue_mentee = process.env.PUBLIC_URL + '/images/blue_mentee.svg';
export const profile_pic_svg = process.env.PUBLIC_URL + '/images/profile_pic_svg.svg';
export const smile_emoji = process.env.PUBLIC_URL + '/images/smile_emoji.svg';
export const white_cross_icon = process.env.PUBLIC_URL + '/images/white_cross_icon.svg';
export const blue_close_icon = process.env.PUBLIC_URL + '/images/blue_close_icon.svg';
export const mp3_icon = process.env.PUBLIC_URL + '/images/mp3_icon.svg';
export const single_tick = process.env.PUBLIC_URL + '/images/single_tick.svg';
export const excel_trans = process.env.PUBLIC_URL + '/images/excel_trans.svg';
export const msword_trans = process.env.PUBLIC_URL + '/images/ms_word_trans.svg';
export const flag_icon = process.env.PUBLIC_URL + '/images/flag_icon.svg';
export const sendButtonIcon = process.env.PUBLIC_URL + '/images/send_button_icon.svg';
export const addButtonPlusIcon = process.env.PUBLIC_URL + '/images/add_button_plus_icon.svg';
export const deleteItemIcon = process.env.PUBLIC_URL + '/images/delete_item_icon.svg';
export const circleAlertIcon = process.env.PUBLIC_URL + '/images/circle_alert.svg';
export const modal_cross_close = process.env.PUBLIC_URL + '/images/modal_cross_close.svg';
export const pauseCircle = process.env.PUBLIC_URL + '/images/PauseCircle.svg';
export const playChatIcon = process.env.PUBLIC_URL + '/images/play_chat_icon.svg';
export const filterOpenIcon = process.env.PUBLIC_URL + '/images/filter_open_icon.svg';
export const filterCloseIcon = process.env.PUBLIC_URL + '/images/filter_close_icon.svg';