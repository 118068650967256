export const rules = {
    email: {
        required: true,
        validate: { matchPattern: (value: string) => /^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/i.test(value.trim()) },
    },
    contactEmail: {
        required: { value: true, message: 'Please enter contact email' },
        pattern: { value: /^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/ , message: 'Please enter email format!' },
    },
    password: {
        required: true,
        validate: { matchPattern: (value: string) => /^(?=.*[A-Z])(?=.*\d).{8,}$/.test(value.trim()) },
    },
    name: {
        required: { value: true, message: 'Please enter partner name' },
        validate: { matchPattern: (value: string) => /[\u0000-\u10FFFF]/gu.test(value.trim()) },
    },
    fullName: {
        required: { value: true, message: 'Please enter partner name' },
        pattern: { value: /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~\u00C0-\u017F]+$/, message: 'Please enter valid name format!' },
    },
    region: {
        required: { value: true, message: 'Please enter region name' },
        pattern: { value: /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~\u00C0-\u017F]+$/, message: 'Please enter valid name format!' },
    },
    zip: {
        required: true,
        validate: { matchPattern: (value: string) => /^\d{5}$/.test(value.trim()) }
    },
    shortName:{
        required: { value: true, message: 'Please enter short name' },
        validate: {
            notOnlySpaces: (value:string) => value.trim().length !== 0 || 'Please enter a valid short name'
        }
    }
}