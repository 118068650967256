import React from "react";
import Cookies from "universal-cookie";
import { expireTime } from "../../utils";
import useLoginStore from "../../hooks/store/common/useLoginStore";
import { skip_warning_icon } from "utils/images";

const SessionExpiredPopup = () => {
    const cookies = new Cookies();
    const { setState } = useLoginStore()

    return (
        <div className="forgot_model_popup d_none">
            <div className="model_popup_detail">
                <div className="event_popup_close_btn">
                </div>
                <div className="flex justify-center">
                </div>


                <div className='i_popup i_session_expired'>
                    <div className='i_popup_content'>
                        <h5 className="text_center fs_18 fw_700 mb_20">Alert</h5>
                        <div className='i_popup_header d_flex_center popup_center_title'>
                            <img src={skip_warning_icon} alt="" />
                        </div>
                        <div className='all_inner_content'>
                            <p className="fs_16 fw_500 text_center mb_30">
                                Sorry! It looks like your session has expired. Please log in again to continue.
                            </p>
                        </div>
                        <div className='i_popup_footer'>
                            <div className='d_flex justify_content_center'>
                                <button className="btn blue_btn" onClick={() => {
                            cookies.remove('user', { path: '/', expires: expireTime() });
                            window.location.reload();
                            setState({ isSessionExpired: false })
                        }}>Login</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SessionExpiredPopup;