import React from 'react';
import { Link } from 'react-router-dom';
import { unauthoraiz_icon } from 'utils/images';

interface UnAuthorizedProps {}

function UnAuthorized({}: UnAuthorizedProps) {
  return (
    <div className="unauthoriz_page">
      <div className="unautho_content">
        <h1>
          4{' '}
          <span>
            <img src={unauthoraiz_icon} alt="unauthoraiz_icon" />
          </span>
          4
        </h1>
        <h2>Oh no! You weren’t supposed to see this</h2>
        <p className="content">The page you’re looking for no longer exists remember you haven’t seen anything :)</p>
        <div className="d_flex_center unauth_btn">
          <Link to="/" className="btn primary_btn blue_btn">
            Go Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
}

export default UnAuthorized;
