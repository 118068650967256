import React from 'react';
import Cookies from 'universal-cookie';
import CryptoJS from 'crypto-js';
import { getAudioDuration } from './audioHandler';
import { down_arrow, group_list_img, pair_list_img } from './images';
import CustomImage from 'components/UI/image/CustomImage';
import moment from 'moment-timezone';

import { ROLE } from 'static';
import DOMPurify from 'dompurify';
import { userFullNameBuilder } from './userFullNameBuilder';

const cookies = new Cookies();
const SECRET_KEY = process.env.REACT_APP_CRYPTO_SECRET_KEY;

const { I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN } = ROLE;

// Access token
export const getAccessToken = () => cookies.get('x-auth-token');
export const isAuthenticated = () => !!getAccessToken();

// const oneDay = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
// export const expireTime = () => new Date(Date.now() + oneDay); // Token expiry time
export const expireTime = () => new Date(Date.now() + 8 * (60 * 60 * 1000)); // Token expiry time
// export const expireTime = () => new Date(Date.now() + 2 * ( 1000))

// Encrypt Cookie
export const cookieEncryption = (key: string, data: unknown) => {
  if (key && data) {
    let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();

    // cookies.set(key, ciphertext, { domain: process.env.REACT_COOKIE_DOMAIN, path: '/', expires: expireTime(), });

    cookies.set(key, ciphertext, { path: '/', expires: expireTime() });
  }
};

export const encryption = (data: object) => {
  let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
  return ciphertext;
};

export const decryption = (data: string) => {
  let bytes = CryptoJS.AES.decrypt(decodeURIComponent(data), SECRET_KEY);
  const cryptoBytes = bytes.toString(CryptoJS.enc.Utf8);
  let decryptedData = cryptoBytes ? JSON.parse(cryptoBytes) : null;
  return decryptedData;
};

// Decrypt Cookie
export const cookieDecryption = (key: string) => {
  const data = cookies.get(key);
  let decryptedData = null;
  if (key && data) {
    let bytes = CryptoJS.AES.decrypt(decodeURIComponent(data), SECRET_KEY);
    decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return decryptedData;
};

export const user = cookieDecryption('user');
export const masterUser = cookieDecryption('masterUser');

export function formattedDate(value: string): string {
  // returns mm/dd/yyyy
  if (value) {
    const date = new Date(value);
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    return formattedDate;
  }
  return '';
}

export function getTimeFormat(time: any, timezone: any) {
  let x = moment();
  let y = moment(time);
  let duration = moment.duration(x.diff(y));
  let day = duration.asDays();
  if (day < 1) {
    return checkTimeZoneDateEvents(time, timezone).convertedTimeforForm;
    // return moment(time).format('hh:mm A');
  } else {
    return checkTimeZoneDateEvents(time, timezone).convertedDateforListing;
    return moment(time).format('MM/DD/YYYY');
  }
}
export const checkTimeZoneDateEvents = (date: any, timezone: any) => {
  let dateTime = new Date(date);

  let convertedTimeforListing = dateTime.toLocaleDateString('en-US', {
    timeZone: timezone?.utc,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  });
  let convertedTimeforForm = dateTime.toLocaleTimeString('en-US', {
    timeZone: timezone?.utc,
    hour: 'numeric',
    minute: 'numeric',
  });
  let convertedDateforListing = dateTime.toLocaleDateString('en-US', {
    timeZone: timezone?.utc,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  return { convertedTimeforListing, convertedTimeforForm, convertedDateforListing };
};
function buildFormData(formData: FormData, data: any, parentKey?: string) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;
    formData.append(parentKey!, value);
  }
}

export function jsonToFormData(data: any): FormData {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
}

export function generateColor() {
  const red = Math.floor(Math.random() * 100);
  const green = Math.floor(Math.random() * 100);
  const blue = Math.floor(Math.random() * 100);

  return '#' + componentToHex(red) + componentToHex(green) + componentToHex(blue);
}

export function sanitizeProfilePicSource(source: string) {
  return DOMPurify.sanitize(source, {
    ALLOWED_URI_REGEXP: /^https?:\/\//,
  });
}

function componentToHex(c: number) {
  const hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}

export const getCustomAvatar = (text: any, classText?: any, isFontSize?: any) => {
  return isFontSize ? (
    <span
      className={`user_pic ${classText}`}
      style={{ background: stringToHslColor(text), color: 'white', fontSize: '11px' }}>
      {generateProfileNameImage(text)}
    </span>
  ) : (
    <span className={`user_pic ${classText}`} style={{ background: stringToHslColor(text), color: 'white' }}>
      {generateProfileNameImage(text)}
    </span>
  );
};

export const stringToHslColor = (str: any, s = 30, l = 80, darkness = 50) => {
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let h = hash % 360;

  // Adjust lightness for darkness
  l = Math.max(0, l - darkness);

  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
};

export function sanitizeKey(inputString: any) {
  const specialChars = {
    '(': 40,
    ')': 41,
    '/': 47,
    '?': 63,
    '[': 91,
    ']': 93,
    "'": 39,
    ',': 44,
    '.': 46,
  };

  let sanitizedString = '';
  for (let char of inputString) {
    // @ts-ignore
    if (specialChars[char] !== undefined) {
      // @ts-ignore
      sanitizedString += `_${specialChars[char]}`;
    } else {
      sanitizedString += char;
    }
  }

  return sanitizedString;
}

export function retrieveOriginalValue(sanitizedString: any) {
  const specialChars = {
    40: '(',
    41: ')',
    47: '/',
    63: '?',
    91: '[',
    93: ']',
    39: "'",
    44: ',',
    46: '.',
  };

  let originalValue = '';
  let i = 0;
  while (i < sanitizedString.length) {
    if (sanitizedString[i] === '_' && i + 3 < sanitizedString.length) {
      const asciiValue = parseInt(sanitizedString.substring(i + 1, i + 4), 10);
      // @ts-ignore
      if (!isNaN(asciiValue) && specialChars[asciiValue] !== undefined) {
        // @ts-ignore
        originalValue += specialChars[asciiValue];
        i += 4;
        continue;
      }
    }
    originalValue += sanitizedString[i];
    i++;
  }

  return originalValue;
}

export function scrollToTopSmooth() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

export const areAllFieldsNotBlank = (fieldValues: any): boolean => {
  for (const value of Object.values(fieldValues)) {
    // Check if the value is falsy or an empty string
    if (value === '') {
      return false;
    }
  }
  return true;
};

export const setLabel = (user: any) => {
  switch (true) {
    case !!user?.role:
      return userFullNameBuilder.build(user?.preferredFname, user?.fname, user?.lname);
    case !!user?.groupName:
      return user?.groupName;

    default:
      return `${user?.mentorId?.preferredFname ?? ''} ${user?.mentorId?.lname ?? ''} Vs ${user?.menteeId?.preferredFname ?? ''} ${user?.menteeId?.lname ?? ''}`;
  }
};

export const getElement = (user: any) => {
  switch (true) {
    case !!user?.role: {
      return user?.profilePic && user?.profilePic !== '' ? (
        <CustomImage src={user?.profilePic} style={{ borderRadius: '100%' }} />
      ) : null;
    }
    case !!user?.groupName:
      return <img src={group_list_img} alt="" />;

    default:
      return (
        <div className="pairs_match pairs_img_view">
          <div className="user_pic">
            <span className="common_match">
              {!!user?.menteeId?.profilePic ? (
                <img
                  className="generate_pic top_pic"
                  src={sanitizeProfilePicSource(user?.menteeId?.profilePic) || undefined}
                  alt=""
                />
              ) : (
                <img
                  src={generateCustomAvatar(
                    user?.menteeId?.preferredFname + ' ' + user?.menteeId?.lname,
                    'small-caps bold 75px Montserrat_ragular'
                  )}
                  alt="profile-pic"
                  className="generate_pic top_pic"
                  style={{ borderRadius: '50%', objectFit: 'cover' }}
                />
              )}

              {!!user?.mentorId?.profilePic ? (
                <img
                  className="generate_pic bottom_pic"
                  src={sanitizeProfilePicSource(user?.menteeId?.profilePic) || undefined}
                  alt=""
                />
              ) : (
                <img
                  src={generateCustomAvatar(
                    user?.mentorId?.preferredFname + ' ' + user?.mentorId?.lname,
                    'small-caps bold 75px Montserrat_ragular'
                  )}
                  alt="profile-pic"
                  className="generate_pic bottom_pic"
                  style={{ borderRadius: '50%', objectFit: 'cover' }}
                />
              )}
            </span>
          </div>
        </div>
      );
  }
};

export const getImage = (user: any) => {
  switch (true) {
    case !!user?.role:
      return user?.profilePic && user?.profilePic !== '' ? user?.profilePic : null;
    case !!user?.groupName:
      return group_list_img;

    default:
      return pair_list_img;
  }
};

export function getClassName(params: string): string {
  switch (params) {
    case I_SUPER_ADMIN:
      return 'super_admin';
    case I_LOCAL_ADMIN:
      return 'im_local_admin';
    case P_SUPER_ADMIN:
      return 'partner_super_admin';
    case P_LOCAL_ADMIN:
      return 'partner_local_admin';
    case 'Invited':
      return 'invited';
    case 'In Progress':
      return 'inprogress';
    case 'Completed - Pending Pre-Match To Dos':
      return 'completed';
    case 'Pre-Match Pending':
      return 'completed';
    case 'Completed':
      return 'completed';
    case 'Not Started':
      return 'notstarted';
    case 'Ready to Be Matched':
      return 'ready_to_be_matched';
    case 'Matched':
      return 'matched';
    case 'Matching In progress':
      return 'matching_inprogress';
    case 'Draft':
      return 'invited';
    case 'Disabled':
      return 'invited';
    case 'Matched Not Registered':
      return 'matched';
    default:
      return '';
  }
}

export function Decryption(data: any) {
  try {
    let bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_CRYPTO_SECRET_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (e) {}
}

export const Encryption = (data: any) => {
  try {
    return CryptoJS.AES.encrypt(data, process.env.REACT_APP_CRYPTO_SECRET_KEY).toString();
  } catch (e) {}
};

function isValidURL(url: any) {
  // Regular expression pattern to match a URL
  var urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

  // Check if the given URL Matches the pattern
  return urlPattern.test(url);
}

export function getFileTypeFromUrl(url: any) {
  if (!isValidURL(url)) {
    return;
  }

  const parsedUrl = new URL(url);

  const pathname = parsedUrl.pathname;
  const extension = pathname.substring(pathname.lastIndexOf('.')).toLowerCase();

  const mimeType = extensionToMimeType(extension);
  return mimeType;
}
function extractFileName(inputString: any) {
  const parts = inputString.split('-');
  if (parts.length > 0) {
    const fileNameWithExtension = parts[parts.length - 1];
    const fileName = fileNameWithExtension.substring(0, fileNameWithExtension.lastIndexOf('.'));
    return fileName.replace(/-/g, ' ');
  } else {
    return null; // No valid file name found
  }
}

// export function getFilenameFromPath(filePath: any) {
//   if (!filePath) {
//     return;
//   }

//   const parts = filePath?.split("/");
//   const lastPart = parts[parts?.length - 1];
//   return extractFileName(lastPart);
// }
export function getFilenameFromPath(filePath: string | null | undefined): string | undefined {
  if (!filePath) {
    return;
  }

  // If there are hyphens, grab the string after the last hyphen and replace underscores
  if (filePath.includes('-')) {
    const parts = filePath.split('-');
    const lastPart = parts[parts.length - 1];
    return lastPart.replace(/_/g, ' ').replace(/\.pdf$/, '');
  }

  // If no hyphens, and the filename has spaces followed by a sequence of numbers and .pdf
  const match = /(.*\S) \d+\.pdf$/.exec(filePath);
  if (match && match[1]) {
    return match[1];
  }

  // If none of the above conditions are met, return undefined
  return;
}

function extensionToMimeType(extension: any) {
  // Define mappings for common extensions to MIME types
  const extensionToMime = {
    '.jpg': 'image/jpeg',
    '.jpeg': 'image/jpeg',
    '.png': 'image/png',
    '.gif': 'image/gif',
    '.mp3': 'audio/mpeg',
    '.pdf': 'application/pdf',
    '.doc': 'application/msword', // Microsoft Word document
    '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Microsoft Word document (OpenXML)
    '.ppt': 'application/vnd.ms-powerpoint', // Microsoft PowerPoint presentation
    '.pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation', // Microsoft PowerPoint presentation (OpenXML)
    '.xls': 'application/vnd.ms-excel', // Microsoft Excel spreadsheet
    '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Microsoft Excel spreadsheet (OpenXML)
    '.csv': 'text/csv', // Comma-separated values
    // Add more extensions and MIME types as needed
  };

  // @ts-ignore
  return extensionToMime[extension] || 'application/octet-stream'; // Default to binary if not found
}

export function formatSeconds(seconds: any) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const parts = [];

  if (hours > 0) {
    parts.push(`${hours.toString().padStart(2, '0')}`);
  }

  parts.push(`${minutes.toString().padStart(2, '0')}`);
  parts.push(`${remainingSeconds.toString().padStart(2, '0')}`);

  return parts.join(':');
}

export const audioErrorHandler = (error: any) => {
  switch (error.name) {
    case 'AbortError': //error from navigator.mediaDevices.getUserMedia
      console.log('An AbortError has occured.');
      break;
    case 'NotAllowedError': //error from navigator.mediaDevices.getUserMedia
      console.log('A NotAllowedError has occured. User might have denied permission.');
      break;
    case 'NotFoundError': //error from navigator.mediaDevices.getUserMedia
      console.log('A NotFoundError has occured.');
      break;
    case 'NotReadableError': //error from navigator.mediaDevices.getUserMedia
      console.log('A NotReadableError has occured.');
      break;
    case 'SecurityError': //error from navigator.mediaDevices.getUserMedia or from the MediaRecorder.start
      console.log('A SecurityError has occured.');
      break;
    case 'TypeError': //error from navigator.mediaDevices.getUserMedia
      console.log('A TypeError has occured.');
      break;
    case 'InvalidStateError': //error from the MediaRecorder.start
      console.log('An InvalidStateError has occured.');
      break;
    case 'UnknownError': //error from the MediaRecorder.start
      console.log('An UnknownError has occured.');
      break;
    default:
      console.log('An error occured with the error name ' + error.name);
  }
};

export async function blobToMP3(blob: any) {
  const mp3Blob = new Blob([blob], { type: 'audio/mp3' });
  // Create a new File object from the Blob
  const mp3File = new File([mp3Blob], 'audio.mp3', {
    type: 'audio/mp3',
  });
  const duration = await getAudioDuration(mp3Blob);

  return { file: mp3File, duration };
}

export const generateProfileNameImage = (name: any) => {
  const [fname, lname] = name.split(/\s+/);
  return `${fname?.toUpperCase()?.[0]}${lname?.toUpperCase()?.[0]}`;
};

export const isDataBlankHandler = (data: any) => {
  return data ? data : '--';
};

export const isBooleanDataBlankHandler = (data: any) => {
  return data ? (data ? 'Yes' : 'No') : '--';
};

export const getIsFieldIsBlank = (formValues: any) => {
  const fieldValue = {
    preferredfname: formValues.preferredfname,
    lname: formValues.lname,
    email: formValues.email,
  };

  let allFiled = areAllFieldsNotBlank(fieldValue);
  return allFiled;
};

export const createSortSpan = (
  fieldName: string,
  headerType: any,
  sortType: any,
  handleSort: (nextHeaderType: string) => void
) => (
  <span
    className={`down_arrow ${headerType === fieldName && 'short_list'} ${headerType === fieldName && (sortType === 'des' || sortType === 'desc') && 'descending'} cursor_pointer ml_10`}
    onClick={() => handleSort(fieldName)}>
    <img src={down_arrow} alt="down_arrow" />
    {headerType === fieldName && (sortType === 'des' || sortType === 'desc') ? (
      <span className="tooltip tooltip_descending">Sort Descending</span>
    ) : (
      <span className="tooltip tooltip_ascending">Sort Ascending</span>
    )}
  </span>
);

export const getFirstString = (str: any) => {
  const words = str.split(' ');
  const firstLetter = words[0].charAt(0);
  const secondLetter = words[1].charAt(0);
  return `${firstLetter}${secondLetter}`;
};

export const generateCustomAvatar = (text: string, style: string) => {
  const canvas = document.createElement('canvas');
  const context: any = canvas.getContext('2d');

  // Draw background
  context.fillStyle = stringToHslColor(text);
  context.fillRect(0, 0, canvas.width, canvas.height);

  // Draw text
  // context.font = " bold 75px Assistant Montserrat_ragular";
  context.font = style;
  context.fillStyle = '#fff';
  context.textAlign = 'center';
  context.textBaseline = 'middle';

  let first = text?.split(' ')[0]?.charAt(0)?.toUpperCase();
  let last = text?.split(' ')[1]?.charAt(0)?.toUpperCase();

  if (!last) {
    last = text?.split(' ')[0]?.charAt(1)?.toUpperCase() || text?.split(' ')[0]?.charAt(0)?.toUpperCase();
  }
  if (!first) {
    first = 'S';
    last = 'U';
  }

  context.fillText(first + last, canvas.width / 2, canvas.height / 2);

  return canvas.toDataURL('image/png');
};

export const getDurationDate = (duration: any, date: string) => {
  let seconds = duration.asSeconds();
  let minutes = duration.asMinutes();
  let hours = duration.asHours();
  let days = duration.asDays();
  let months = duration.asMonths();
  let years = duration.asYears();

  if (minutes >= 525600) {
    return `${years.toFixed()} year ago`;
  } else if (months >= 1) {
    return moment(date).format('MMM DD');
  } else if (days < 2) {
    if (seconds < 60) {
      return 'now';
    } else if (minutes <= 1) {
      return `${minutes?.toString()?.split('.')[0]} min ago`;
    } else if (minutes > 1 && minutes < 60) {
      return `${minutes?.toString()?.split('.')[0]} mins ago`;
    } else if (minutes >= 60 && minutes < 120) {
      return `${hours?.toString()?.split('.')[0]} hour ago`;
    } else if (minutes >= 120 && minutes < 1440) {
      return `${hours?.toString()?.split('.')[0]} hours ago`;
    } else {
      return `${days?.toString()?.split('.')[0]} day ago`;
    }
  } else if (days > 1) {
    return `${days?.toString()?.split('.')[0]} days ago`;
  }
};

export const autocompleteOff = () => {
  const inputElements: any = document.getElementsByTagName('input');
  if (inputElements) {
    for (const inputElement of inputElements) {
      inputElement.setAttribute('autocomplete', 'off');
    }
  }
};

export const firstLastNameRegex = /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~\u00C0-\u017F]+$/;

export function trimObjectValues(obj: any): any {
  const trimmedObject: any = {};

  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      trimmedObject[key] = obj[key].trim();
    } else {
      trimmedObject[key] = obj[key];
    }
  }

  return trimmedObject;
}

export function formatMessageDate(inputDate: any, timezone: any) {
  if (!inputDate || !moment(inputDate).isValid()) {
    return 'Invalid date';
  }

  const userTimezone = timezone?.utc || 'UTC';
  const date = moment(inputDate).tz(userTimezone);
  const today = moment().tz(userTimezone).startOf('day');
  const yesterday = moment().tz(userTimezone).subtract(1, 'days').startOf('day');

  if (date.isSame(today, 'day')) {
    return `Today ${date.format('hh:mm A')}`;
  } else if (date.isSame(yesterday, 'day')) {
    return `Yesterday ${date.format('hh:mm A')}`;
  } else {
    return `${date.format('ddd, MMM D [at] hh:mm A')}`;
  }
}

export const UTCToPartnerTimeZone = (date: any, timezone: any) => {
  moment.utc(date).tz(timezone?.utc);
  return moment.utc(date).tz(timezone?.utc);
};

export const UTCToFormattedPartnerTimeZone = (date: any, timezone: any) => {
  return moment.utc(date).tz(timezone?.utc)?.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
};

export function formatPairDate(dateString: string) {
  if (!dateString || dateString === 'Invalid date') {
    return '--';
  }

  const givenDate = moment.parseZone(dateString);
  const currentDate = moment();

  const hours = givenDate.hours();

  const minutes = givenDate.minutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

  if (givenDate.isSame(currentDate, 'day')) {
    return `Today at ${formattedHours}:${minutes} ${ampm}`;
  }

  const daysDiff = currentDate.diff(givenDate, 'days');

  if (daysDiff < 7 && givenDate.day() < currentDate.day()) {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dayOfWeek = daysOfWeek[givenDate.day()];
    return `${dayOfWeek} at ${formattedHours}:${minutes} ${ampm}`;
  }

  return `${givenDate.month() + 1}/${givenDate.date()}/${givenDate.year().toString().slice(-2)}`;
}

export const checkTimeZoneDate = (date: any, timezone: any) => {
  // Check if the timezone object and its 'utc' property are defined
  if (timezone && timezone.utc) {
    // Use the timezone string from the timezone object
    const convertedTime = moment.utc(date).toDate().toLocaleDateString('en-US', {
      timeZone: timezone.utc,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    return convertedTime;
  } else {
    // Handle the case when the timezone or its 'utc' property is undefined
    console.error('Invalid or missing timezone provided');
    return null; // Or any other suitable fallback value
  }
};
export function PartnerTimeZoneToUTC(date: any, timezone: any) {
  var m = moment.tz(date, 'MM/DD/YYYY h:mm A', timezone?.utc).utc().format('');
  return m;
}
export const raceTag = [
  {
    label: 'Asian / Pacific Islander',
    value: 'Asian / Pacific Islander',
  },
  {
    label: 'Black / African American',
    value: 'Black / African American',
  },
  {
    label: 'Hispanic or Latinx (May be of any race)',
    value: 'Hispanic or Latinx (May be of any race)',
  },
  {
    label: 'I identify with a race not listed here',
    value: 'I identify with a race not listed here',
  },
  {
    label: 'I would prefer not to indicate my race',
    value: 'I would prefer not to indicate my race',
  },
  {
    label: 'Native American (Aleutian Islander)',
    value: 'Native American (Aleutian Islander)',
  },
  {
    label: 'White (Non-Hispanic)',
    value: 'White (Non-Hispanic)',
  },
];

export function isSixDaysAgo(dateString: string) {
  const date = moment(dateString);
  const currentDate = moment();
  const differenceInDays = Math.abs(currentDate.diff(date, 'days'));
  return differenceInDays <= 6;
}

export function getClassNamePairMentor(obj: any) {
  let className = '';
  switch (true) {
    case !Object?.keys(obj)?.length:
      className = 'gray_chat_box';
      break;
    case obj?.isEventAttendByMentor &&
      obj?.isSendPairPromptInWeek &&
      obj?.isSendMessageInWeekByMentor &&
      obj?.isEventAcceptByMentor:
      className = 'pr_event_msg';
      break;
    case !obj?.isEventAttendByMentor &&
      obj?.isSendPairPromptInWeek &&
      obj?.isSendMessageInWeekByMentor &&
      obj?.isEventAcceptByMentor:
      className = 'pr_event_invitation';
      break;
    case !obj?.isEventAttendByMentor &&
      obj?.isSendPairPromptInWeek &&
      !obj?.isSendMessageInWeekByMentor &&
      obj?.isEventAcceptByMentor:
      className = 'pr_event_invitation_decline';
      break;
    case obj?.isEventAttendByMentor &&
      obj?.isSendPairPromptInWeek &&
      obj?.isSendMessageInWeekByMentor &&
      !obj?.isEventAcceptByMentor:
      className = 'pr_event_msg';
      break;
    case obj?.isEventAttendByMentor &&
      obj?.isSendPairPromptInWeek &&
      !obj?.isSendMessageInWeekByMentor &&
      obj?.isEventAcceptByMentor:
      className = 'pr_event_msg_pair';
      break;
    case obj?.isEventAttendByMentor &&
      !obj?.isSendPairPromptInWeek &&
      obj?.isSendMessageInWeekByMentor &&
      obj?.isEventAcceptByMentor:
      className = 'pr_border_event_msg';
      break;
    case !obj?.isEventAttendByMentor &&
      obj?.isSendPairPromptInWeek &&
      !obj?.isSendMessageInWeekByMentor &&
      !obj?.isEventAcceptByMentor:
      className = 'pr_border_onl_box';
      break;
    case !obj?.isEventAttendByMentor &&
      !obj?.isSendPairPromptInWeek &&
      obj?.isSendMessageInWeekByMentor &&
      !obj?.isEventAcceptByMentor:
      className = 'both_msg_box';
      break;
    case !obj?.isEventAttendByMentor &&
      !obj?.isSendPairPromptInWeek &&
      !obj?.isSendMessageInWeekByMentor &&
      obj?.isEventAcceptByMentor:
      className = 'pr_event_invitation_accept';
      break;
    case obj?.isEventAttendByMentor &&
      !obj?.isSendPairPromptInWeek &&
      !obj?.isSendMessageInWeekByMentor &&
      !obj?.isEventAcceptByMentor:
      className = 'pr_event_msg_ind';
      break;
    case obj?.isEventAttendByMentor &&
      !obj?.isSendPairPromptInWeek &&
      !obj?.isSendMessageInWeekByMentor &&
      obj?.isEventAcceptByMentor:
      className = 'pr_event_msg_ind';
      break;
    case !obj?.isEventAttendByMentor &&
      obj?.isSendPairPromptInWeek &&
      obj?.isSendMessageInWeekByMentor &&
      !obj?.isEventAcceptByMentor:
      className = 'pr_msg_box';
      break;
    case obj?.isEventAcceptByMentor &&
      !obj?.isEventAttendByMentor &&
      !obj?.isSendPairPromptInWeek &&
      obj?.isSendMessageInWeekByMentor:
      className = 'pr_border_not_event_msg';
      break;
    case obj?.isEventAttendByMentor &&
      obj?.isSendPairPromptInWeek &&
      !obj?.isSendMessageInWeekByMentor &&
      !obj?.isEventAcceptByMentor:
      className = 'pr_event_msg_pair';
      break;
    default:
      className = '';
  }
  return className;
}

export function getClassNamePairMentee(obj: any) {
  let className = '';
  switch (true) {
    case !Object?.keys(obj)?.length:
      className = 'gray_chat_box';
      break;
    case obj?.isEventAttendByMentee &&
      obj?.isSendPairPromptInWeek &&
      obj?.isSendMessageInWeekByMentee &&
      obj?.isEventAcceptByMentee:
      className = 'pr_event_msg';
      break;
    case !obj?.isEventAttendByMentee &&
      obj?.isSendPairPromptInWeek &&
      obj?.isSendMessageInWeekByMentee &&
      obj?.isEventAcceptByMentee:
      className = 'pr_event_invitation';
      break;
    case !obj?.isEventAttendByMentee &&
      obj?.isSendPairPromptInWeek &&
      !obj?.isSendMessageInWeekByMentee &&
      obj?.isEventAcceptByMentee:
      className = 'pr_event_invitation_decline';
      break;
    case obj?.isEventAttendByMentee &&
      obj?.isSendPairPromptInWeek &&
      obj?.isSendMessageInWeekByMentee &&
      !obj?.isEventAcceptByMentee:
      className = 'pr_event_msg';
      break;
    case !obj?.isEventAcceptByMentee &&
      obj?.isSendPairPromptInWeek &&
      !obj?.isSendMessageInWeekByMentee &&
      obj?.isEventAttendByMentee:
      className = 'pr_event_msg_pair';
      break;
    case obj?.isEventAttendByMentee &&
      obj?.isSendPairPromptInWeek &&
      !obj?.isSendMessageInWeekByMentee &&
      obj?.isEventAcceptByMentee:
      className = 'pr_event_msg_pair';
      break;
    case obj?.isEventAttendByMentee &&
      !obj?.isSendPairPromptInWeek &&
      obj?.isSendMessageInWeekByMentee &&
      obj?.isEventAcceptByMentee:
      className = 'pr_border_event_msg';
      break;
    case !obj?.isEventAttendByMentee &&
      obj?.isSendPairPromptInWeek &&
      !obj?.isSendMessageInWeekByMentee &&
      !obj?.isEventAcceptByMentee:
      className = 'pr_border_onl_box';
      break;
    case !obj?.isEventAttendByMentee &&
      !obj?.isSendPairPromptInWeek &&
      obj?.isSendMessageInWeekByMentee &&
      !obj?.isEventAcceptByMentee:
      className = 'both_msg_box';
      break;
    case !obj?.isEventAttendByMentee &&
      !obj?.isSendPairPromptInWeek &&
      !obj?.isSendMessageInWeekByMentee &&
      obj?.isEventAcceptByMentee:
      className = 'pr_event_invitation_accept';
      break;
    case obj?.isEventAttendByMentee &&
      !obj?.isSendPairPromptInWeek &&
      !obj?.isSendMessageInWeekByMentee &&
      !obj?.isEventAcceptByMentee:
      className = 'pr_event_msg_ind';
      break;
    case obj?.isEventAttendByMentee &&
      !obj?.isSendPairPromptInWeek &&
      !obj?.isSendMessageInWeekByMentee &&
      obj?.isEventAcceptByMentee:
      className = 'pr_event_msg_ind';
      break;
    case !obj?.isEventAttendByMentee &&
      obj?.isSendPairPromptInWeek &&
      obj?.isSendMessageInWeekByMentee &&
      !obj?.isEventAcceptByMentee:
      className = 'pr_msg_box';
      break;
    case obj?.isEventAcceptByMentee &&
      !obj?.isEventAttendByMentee &&
      obj?.isSendMessageInWeekByMentee &&
      !obj?.isSendPairPromptInWeek:
      className = 'pr_border_not_event_msg';
      break;
    default:
      className = '';
  }
  return className;
}
export const replaceStartOfUrl = (url: any) => {
  let baseUrl;
  baseUrl = process.env.REACT_APP_CLOUDFRONT_URL;
  // Replace the starting part of the input URL with the baseUrl
  return url.replace(/^(https?:\/\/[^\/]+)/, baseUrl);
};
export const replaceShortNameAtIndex = (dataArray: any, newShortName: string, index: number) => {
  if (index === -1) {
    return dataArray;
  }
  const updatedArray = [...dataArray];
  const item = updatedArray[index];
  if (item.group && item.group.partner) {
    updatedArray[index] = {
      ...item,
      group: {
        ...item.group,
        groupName: newShortName,
        partner: {
          ...item.group.partner,
          shortName: newShortName,
        },
      },
    };
  }
  return updatedArray;
};
