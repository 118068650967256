import { useReducer, useState } from 'react';
import axiosClient from 'api/axiosClient';
import { decryption } from 'utils';

interface useGetLoginUserState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  error: any;
}

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  data: { account: [], _id: '' },
  error: null,
};

const reducer = (state: useGetLoginUserState, payload: Partial<useGetLoginUserState>) => ({ ...state, ...payload });

export default function useGetLoginUsers() {
  const [state, setState] = useReducer(reducer, initialState);

  const getLoginUsers = async (req: any) => {
    setState({ ...state, isLoading: false });

    try {
      const data = await axiosClient.post('/admin/current/loggedIn/users', req).then(res => res.data);

      if (!data?.error) {
        const response = decryption(data.data);

        const { _id, account } = response;

        setState({ isSuccess: true, data: { ...response, _id, account: [...account] } });

        return;
      }
    } catch (error: any) {
      setState({ isError: true, error });
    } finally {
      setState({ isLoading: false });
    }
  };

  return {
    ...state,
    getLoginUsers,
  };
}
