import { useNavigate, useSearchParams } from 'react-router-dom';
import { back_arrow_icon } from 'utils/images';

/**
 * Default title component
 * @param text
 * @constructor
 */
const DefaultComponent = ({ text }: { text: string }) => <p className="user_title">{text}</p>;

/**
 * Back button component
 * @param text - {string} - name of parent page
 * @param navigateTo - {string} - parent page path
 */
const BackComponent = ({ text, navigateTo }: { text: string; navigateTo: string }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  let nextText: string = text;
  window.location.href.includes('regionId');
  switch (true) {
    case tab === 'Regions':
      nextText = 'Regions List';
      break;
    case window.location.href.includes('regionId'):
      nextText = 'Regions List';
      break;
    default:
      break;
  }

  return (
    <p className="back_btn return_page">
      <span
        className="back_icon"
        onClick={() => {
          if (text === 'Matches List') {
            navigate(-1);
          } else {
            navigate(navigateTo);
          }
        }}>
        <img src={back_arrow_icon} alt="back_arrow_icon" />
      </span>
      Back to <span className="back_text"> {nextText}</span>
    </p>
  );
};

/**
 * Header back data
 * Dynamic data for title component or for back button component per page
 */
export const HeaderBackData = {
  '/participants/admin-users': <DefaultComponent text={'Participants'} />,
  '/participants/mentors': <DefaultComponent text={'Participants'} />,
  '/participants/mentees': <DefaultComponent text={'Participants'} />,
  '/participants/admin-users/add-admin-user': (
    <BackComponent text="Admin User List" navigateTo="/participants/admin-users" />
  ),
  '/partners': <DefaultComponent text={'Partners & Regions'} />,
  '/partners/partner-detail': <BackComponent text="Partner List" navigateTo="/partners" />,
  '/partners/add-partner-user': <BackComponent text="Partner List" navigateTo="/partners" />,
  '/participants/mentors/add-mentor': <BackComponent text="Mentor List" navigateTo="/participants/mentors" />,
  '/participants/mentors/edit-mentor/:id': <BackComponent text="Mentor List" navigateTo="/participants/mentors" />,
  '/participants/mentors/mentor-details/:id': <BackComponent text="Mentor List" navigateTo="/participants/mentors" />,
  '/participants/mentees/edit-mentee/:id': <BackComponent text="Mentees List" navigateTo="/participants/mentees" />,
  '/participants/mentees/add-mentee': <BackComponent text={'Mentees List'} navigateTo="/participants/mentees" />,
  '/participants/mentees/mentee-details': <BackComponent text={'Mentees List'} navigateTo="/participants/mentees" />,
  '/matches': <DefaultComponent text={'Matches'} />,
  '/matches/potential-match-details': <BackComponent text={'Matches List'} navigateTo="/matches" />,
  '/matches/selected-match-details': <BackComponent text={'Matches List'} navigateTo="/matches" />,
  '/pairs': <DefaultComponent text={'Pairs'} />,
  '/pairs/pair-activity': <BackComponent text={'Pairs List'} navigateTo="/pairs" />,
  '/events': <DefaultComponent text={'Events'} />,
  '/add-events': <BackComponent text={'Events List'} navigateTo="/events" />,
  '/events-detail/:id': <BackComponent text={'Events'} navigateTo="/events" />,
  '/content': <DefaultComponent text={'Content'} />,
  '/content/create-content': <BackComponent text={'Content List'} navigateTo="/content" />,
  '/content/create-survey': <BackComponent text={'Content List'} navigateTo="/content" />,
  '/groups': <DefaultComponent text={'Groups'} />,
  '/add-groups': <BackComponent text={'Groups List'} navigateTo="/groups" />,
  '/groups-detail/:id': <BackComponent text={'Groups List'} navigateTo="/groups" />,
  '/team-inbox': <DefaultComponent text={'Messages'} />,
  '/team-inbox/:id': <BackComponent text={'Messages List'} navigateTo="/team-inbox" />,
  '/reporting': <DefaultComponent text={'Progress on Goals'} />,
  '/focus-messages': <DefaultComponent text={'Flag Alert'} />,
  '/focus-messages/:id': <BackComponent text={'Flag Alert'} navigateTo="/focus-messages" />,
};
