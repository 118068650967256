import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Logo } from 'utils/images';
import { SIDE_MENU_LIST } from './icons';
import { useLocation } from 'react-router-dom';
import { cookieDecryption } from 'utils';
import useAdminUsers from 'hooks/store/admin-users/useAdminUsers';

type SideMenuProps = {};

function SideMenu({}: SideMenuProps) {
  const location = useLocation();
  const [, pathname] = location.pathname.split('/');
  const auth = cookieDecryption('user');

  const navigate = useNavigate();

  const pageChanger = (title: string, path: string) => {
    navigate(path);
  };
  const handleContextMenu = (e: any) => {
    // Prevent the default context menu behavior
    e.preventDefault();
  };
  const { setState } = useAdminUsers();

  return (
    <div className="app">
      <p className="logo-box mb-50">
        <div
          style={{
            height: '54px',
            width: '178px',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '50px',
          }}>
          <img src={auth?.logo ?? Logo} alt="logo" style={{ width: '178px', height: '54px' }} />
        </div>
      </p>
      <div className="dashboard-menu">
        <div className="menu-wrapper pt_10">
          <div className="menu">
            {SIDE_MENU_LIST.map(
              ({ title, icon: Icon, path, allowedRoles }) =>
                allowedRoles.includes(auth?.role) && (
                  <div
                    className={`cursor_pointer menu_list ${(path === pathname || path.includes(pathname)) && 'active'}`}
                    onClick={() => {
                      setState({ addClass: false });
                      pageChanger(title, path);
                    }}
                    onContextMenu={handleContextMenu}>
                    <div className="flex-center menu_name_icon">
                      <span className="menu-icon">
                        <Icon />
                      </span>
                      <span className="menu-text">{title}</span>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(SideMenu);
