import { ALLOWED_DOMAINS } from 'static';

/**
 * Validates a URL to prevent open redirect vulnerabilities by checking against a whitelist of allowed domains.
 *
 * @param {string} url - The URL to validate. Can be an absolute URL or a relative path.
 *
 * @returns {boolean} Returns true if the URL is:
 *   - A valid relative path starting with '/', or
 *   - A properly formatted URL with a domain that matches the ALLOWED_DOMAINS list or their subdomains
 *
 * @throws {TypeError} The function doesn't throw, but the URL constructor might throw if the URL is malformed.
 *   These errors are caught internally and result in returning false.
 *
 * @example
 * // Returns true for relative paths
 * isValidRedirectUrl('/participants'); // true
 *
 * // Returns true for allowed domains
 * isValidRedirectUrl('https://imentor.org/path'); // true
 * isValidRedirectUrl('https://cms.imentor.org/participants'); // true
 *
 * // Returns false for unauthorized domains
 * isValidRedirectUrl('https://malicious-site.com'); // false
 *
 * // Returns false for malformed URLs
 * isValidRedirectUrl('not-a-url'); // false
 *
 * @security This function is designed to prevent open redirect vulnerabilities by validating
 * redirect URLs against a predefined list of allowed domains. It should be used whenever
 * processing redirect URLs from untrusted sources.
 */

export const validateRedirectURL = (url: string): boolean => {
  try {
    // check if it's a relative URL (starts with /)
    if (url.startsWith('/')) {
      return true;
    }

    // check if it's a valid URL
    const urlObj = new URL(url);

    // Check if the domain is in our allowed list
    return ALLOWED_DOMAINS.some(domain => urlObj.hostname === domain || urlObj.hostname.endsWith(`.${domain}`));
  } catch (e) {
    // If URL parsing fails, return false
    return false;
  }
};
