import { useReducer } from 'react';
import axiosClient from "api/axiosClient";
import useToaster from 'hooks/store/toast/useToaster';
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import { expireTime } from 'utils';

interface useLogoutState {
    isLoading: boolean,
    isSuccess: boolean,
    isError: boolean,
    data: any,
    error: any
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    error: null
}

const reducer = (state: useLogoutState, payload: Partial<useLogoutState>) => ({ ...state, ...payload });

export default function useLogout() {
    const [state, setState] = useReducer(reducer, initialState);
    const toaster = useToaster();
    const navigate = useNavigate();
    const cookies = new Cookies();

    const logoutUser = async () => {
        setState({ isLoading: true })
        try {
            const data = await axiosClient.get('/admin/logout').then((res) => res.data);
            if (!data.error) {
                cookies.remove('user', { path: '/', expires: expireTime() });
                cookies.remove('masterUser', { path: '/', expires: expireTime() });
                localStorage.setItem("userStatus", "logged Out");
                navigate('/')
                return;
            }
            toaster.showToast('Danger', data.msg)
        } catch (error) {
            setState({ isError: true, error });
        } finally {
            setState({ isLoading: false });
        }
    }

    return {
        ...state,
        logoutUser,
    }
}
