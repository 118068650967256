import { lazy } from 'react';
import { ROLE } from 'static/index';

const Login = lazy(() => import('views/login/Login'));
const ForgotPassword = lazy(() => import('views/forgot-password/ForgotPassword'));
const TokenExpired = lazy(() => import('views/token-handler/TokenExpired'));
const ChangePassword = lazy(() => import('views/change-password/ChangePassword'));
const Register = lazy(() => import('views/register/Register'));
const LoginAfterPermission = lazy(() => import('views/login/LoginAfterPermission'));

const Participants = lazy(() => import('views/participants'));
const AdminUsers = lazy(() => import('views/admin-users/AdminUsers'));
const AddAdminUser = lazy(() => import('views/add-admin-user/AddAdminUser'));
const Partners = lazy(() => import('views/partners/Partners'));
const PartnerDetails = lazy(() => import('views/partners/PartnerDetails'));
const AddPartnerUser = lazy(() => import('views/add-partner-user/AddPartnerUser'));
const Mentors = lazy(() => import('views/Mentors'));
const AddMentees = lazy(() => import('views/Mentees/AddMentees'));
const EditMentees = lazy(() => import('views/Mentees/UpdateMentees'));
const MenteeDetails = lazy(() => import('views/Mentees/MenteeDetails/MenteeDetails'));
const AddMentors = lazy(() => import('../../views/Mentors/AddMentor'));
const SelectedMatchDetails = lazy(() => import('views/Matches/PairsMatches/SelectedMatchDetails'));
const PotentialMatchDetails = lazy(() => import('views/Matches/PotentialMatches/PotentialMatchesDetails'));
const MentorDetails = lazy(() => import('views/mentor-details/MentorDetails'));
const Mentees = lazy(() => import('views/Mentees'));
const Matches = lazy(() => import('views/Matches'));
const FocusMessages = lazy(() => import('views/focus-messages'));
const UnAuthorized = lazy(() => import('views/unauthorized/UnAuthorized'));
const EditMentors = lazy(() => import('../../views/Mentors/EditMentor'));
const Pairs = lazy(() => import('views/Pairs'));
const PairActivity = lazy(() => import('views/Pairs/pairActivity/PairActivity'));
const Groups = lazy(() => import('views/groups/Groups'));
const AddGroup = lazy(() => import('views/add-group/AddGroup'));
const GroupDetail = lazy(() => import('views/group-detail/GroupDetail'));
const Activities = lazy(() => import('views/activities/Activities'));
const Messages = lazy(() => import('views/Messages'));
const SwitchAccount = lazy(() => import('views/switch-account/SwitchAccount'));

const Content = lazy(() => import('views/content/Content'));
const CreateContent = lazy(() => import('views/content/CreateContent'));
const CreateSurvey = lazy(() => import('views/create-survey/CreateSurvey'));
const Events = lazy(() => import('views/events/Events'));
const EventsDetail = lazy(() => import('views/events/EventDetail'));
const AddEvents = lazy(() => import('views/add-events/AddEvents'));
const Reporting = lazy(() => import('views/Reporting'));

const { I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN } = ROLE;

/**
 * Private route list
 */
export const privateRouteList = [
  {
    title: 'participants',
    path: '/participants/:type',
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: Participants,
  },
  {
    title: 'add-admin-user',
    path: '/participants/admin-users/add-admin-user',
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN],
    element: AddAdminUser,
  },
  {
    title: 'partners',
    path: '/partners',
    allowedRoles: [I_SUPER_ADMIN],
    element: Partners,
  },
  {
    title: 'add-partner-user',
    path: 'partners/add-partner-user',
    allowedRoles: [I_SUPER_ADMIN],
    element: AddPartnerUser,
  },
  {
    title: 'partner-detail',
    path: 'partners/partner-detail',
    allowedRoles: [I_SUPER_ADMIN],
    element: PartnerDetails,
  },
  {
    title: 'Add Mentor',
    path: '/participants/mentors/add-mentor',
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: AddMentors,
  },
  {
    title: 'Reporting',
    path: '/reporting',
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: Reporting,
  },
  {
    title: 'selected Match Details',
    path: 'Matches/selected-match-details',
    allowedRoles: [P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: SelectedMatchDetails,
  },
  {
    title: 'potential match Details',
    path: 'Matches/potential-match-details',
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: PotentialMatchDetails,
  },
  {
    title: 'mentor Details',
    path: '/participants/mentors/mentor-details/:id',
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: MentorDetails,
  },
  {
    title: 'Add Mentees',
    path: '/participants/mentees/add-mentee',
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: AddMentees,
  },
  {
    title: 'Mentee Details',
    path: '/participants/mentees/mentee-details',
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: MenteeDetails,
  },
  {
    title: 'matches',
    path: '/matches',
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: Matches,
  },
  {
    title: 'Edit Mentor',
    path: '/participants/mentors/edit-mentor/:id',
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: EditMentors,
  },
  {
    title: 'Pairs',
    path: '/pairs',
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: Pairs,
  },
  {
    title: 'Pair Activity',
    path: 'pairs/pair-activity',
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: PairActivity,
  },
  {
    title: 'groups',
    path: '/groups',
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: Groups,
  },
  {
    title: 'add-groups',
    path: '/add-groups',
    allowedRoles: [P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: AddGroup,
  },
  {
    title: 'groups-detail',
    path: '/groups-detail/:groupId',
    allowedRoles: [P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: GroupDetail,
  },
  {
    title: 'unauthorized',
    path: '/unauthorized',
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: UnAuthorized,
  },
  {
    title: 'Edit Mentees',
    path: '/participants/mentees/edit-mentee/:id',
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: EditMentees,
  },
  {
    title: 'content',
    path: '/content',
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: Content,
  },
  {
    title: 'content',
    path: '/content/create-content',
    allowedRoles: [P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: CreateContent,
  },
  {
    title: 'create-survey',
    path: '/content/create-survey',
    allowedRoles: [I_SUPER_ADMIN],
    element: CreateSurvey,
  },
  {
    title: 'activities',
    path: '/activities',
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: Activities,
  },
  {
    title: 'Team Inbox',
    path: '/team-inbox',
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: Messages,
  },
  {
    path: '/team-inbox/:userId',
    element: Messages,
    title: 'Messages',
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
  },
  {
    title: 'Focus Messages',
    path: '/focus-messages',
    element: FocusMessages,
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
  },
  {
    title: 'Focus Message',
    path: '/focus-messages/:targetFocusUserId',
    element: FocusMessages,
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
  },
  {
    title: 'events',
    path: '/events',
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: Events,
  },
  {
    title: 'events',
    path: '/events-detail/:id',
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: EventsDetail,
  },
  {
    title: 'switch-account',
    path: '/switch-account',
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: SwitchAccount,
    isHideLayout: true,
  },
  {
    title: 'Add Events',
    path: '/add-events',
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: AddEvents,
  },
];

/**
 * Public route list
 */
export const publicRouteList = [
  {
    title: 'token-expired',
    path: '/token-expired',
    element: TokenExpired,
  },
  {
    title: 'login',
    path: '/',
    element: Login,
  },
  {
    title: 'forgot-password',
    path: '/forgot-password',
    element: ForgotPassword,
  },
  {
    title: 'change-password',
    path: '/change-password/:params',
    element: ChangePassword,
  },
  {
    title: 'register',
    path: '/register',
    element: Register,
  },
  {
    title: 'login',
    path: '/login',
    element: LoginAfterPermission,
  },
];
