/**
 * Roles
 */
export const ROLE = Object.freeze({
  I_SUPER_ADMIN: 'Super Admin',
  I_LOCAL_ADMIN: 'IM Local Admin',
  P_SUPER_ADMIN: 'Partner Super Admin',
  P_LOCAL_ADMIN: 'Partner Local Admin',
  MENTOR: 'Mentor',
  MENTEE: 'Mentee',
});

/**
 * Available prompt type options
 */
export const PROMPT_TYPE_OPTIONS = Object.freeze({
  MULTI_CHOICE: { label: 'Multi-Choice', value: 'Multi-Choice' },
  OPEN_RESPONSE: { label: 'Open Response', value: 'Open-Response' },
  NUDGE: { label: 'Nudge', value: 'Nudge' },
  PAIR_CONNECTION: { label: 'Pair Connection', value: 'Pair-Connection' },
});

/**
 * Prompts textarea placeholders
 */
export const PROMPTS_TEXTAREA_PLACEHOLDER = Object.freeze({
  NUDGE:
    'Keep it short, action-oriented, and positive (e.g., ‘Reminder: Please complete your weekly check-in by Friday.’)',
  OPEN_RESPONSE: 'Ask an open-ended question to encourage detailed responses...',
  PAIR_CONNECTION: 'Write a prompt to spark meaningful dialogue...',
  MULTI_CHOICE: "Ask a clear, simple question to engage your audience (e.g., 'What is your top priority this week?')",
});

export const ALLOWED_DOMAINS = Object.freeze([
  'imentor.org',
  'cms.imentor.org',
  'pilot.imentor.org',
  'imentordev.org',
  'cms.imentordev.org',
  'dev.imentor.org',
  'cms.dev.imentordev.org',
]);
