import CustomImage from 'components/UI/image/CustomImage';
import useSocket from 'hooks/query/messages/useSocket';
import useContentStore from 'hooks/store/content/useContentStore';
import useEvents from 'hooks/store/events/useEvents';
import useSocketStore from 'hooks/store/messages/useSocketStore';
import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  UTCToFormattedPartnerTimeZone,
  UTCToPartnerTimeZone,
  checkTimeZoneDateEvents,
  cookieDecryption,
  encryption,
  generateCustomAvatar,
  getDurationDate,
} from 'utils';
import { unread_notify } from 'utils/images';

const Notification = (isOpen: any) => {
  const { notificationResponse } = useSocketStore();

  const { sendEvent } = useSocket();
  const user = cookieDecryption('user');
  useEffect(() => {
    if (isOpen?.isOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'visible';
    }
  }, [isOpen?.isOpen]);

  const handleMarkReadAll = (event: any) => {
    const payload = {
      event: 'readNotification',
      data: { type: 'all' },
    };
    sendEvent({ payload });
  };

  const handleMarkRead = (event: any) => {
    event.stopPropagation();
    const payload = {
      event: 'readNotification',
      data: { type: 'all' },
    };
    sendEvent({ payload });
  };
  const { setState } = useEvents();
  const { setState: setContentState } = useContentStore();
  const navigate = useNavigate();

  return (
    <div className="i_popup_notification" id="notificationRef">
      <div className="i_popup_notify_content count_notification_pop">
        <div className="inner_pop_notify">
          <div className="i_popup_header d_flex_between">
            <h4 className="fw_600 fs_20">Notifications</h4>
            {notificationResponse?.data?.length > 0 && (
              <span className="fw_400 fs_16 cursor_pointer" onClick={handleMarkReadAll}>
                Mark all as read
              </span>
            )}
          </div>
        </div>

        <div className="i_pop_notify_details">
          <div className="all_inner_content">
            <ul className="notification_wrapper">
              {notificationResponse?.data?.map((notification: any) => {
                let x = moment();
                let y = moment(moment(notification?.createdAt));
                let x2 = UTCToPartnerTimeZone(
                  new Date()?.toISOString(),
                  notification?.users?.partnerAdmin?.timezone || notification?.users?.region?.timezone || user?.timezone
                );
                let y2 = UTCToPartnerTimeZone(
                  notification?.createdAt,
                  notification?.users?.partnerAdmin?.timezone || notification?.users?.region?.timezone || user?.timezone
                );
                let duration2 = moment?.duration(x2?.diff(y2));
                let duration = moment?.duration(x?.diff(y));
                let type = notification?.type;
                const emojis: any = {
                  Heart: '❤️',
                  Smile: '😂',
                  Sad: '😞',
                  DisBelief: '😅',
                  ThumsUp: '👍',
                };
                let temp = notification?.content?.split('reacted on your');

                return (
                  <>
                    {type === 'Reaction' && (
                      <li className={`${notification?.read ? '' : 'unread'}`} key={notification?._id}>
                        <div
                          className="notification_main d_flex align_items_center cursor_pointer"
                          onClick={() => {
                            const payload = {
                              event: 'readNotification',
                              data: { notiId: notification?._id },
                            };
                            sendEvent({ payload });
                            const query = {
                              firstName: notification?.users?.preferredFname || notification.users?.fname,
                              lastName: notification.users?.lname,
                              profilePic: notification.users?.profilePic ? notification.users?.profilePic : '',
                            };
                            navigate(
                              `/team-inbox/${notification?.users?._id}?query=${encodeURIComponent(encryption(query))}`,
                              {}
                            );
                          }}>
                          <div className="notify_pic">
                            <CustomImage
                              src={
                                notification?.users?.profilePic ||
                                generateCustomAvatar(
                                  `${notification?.users?.preferredFname || notification?.users?.fname} ${notification?.users?.lname}`,
                                  'small-caps bold 75px Montserrat_ragular'
                                )
                              }
                            />
                          </div>
                          <div className="notification_inner d_flex flex_direction_column">
                            <p className="user_project">
                              {`${notification?.users?.preferredFname} ${notification?.users?.lname} `}{' '}
                              <span>
                                {' '}
                                {emojis?.[notification?.message?.reactions?.[0]] &&
                                  'reacted ' + emojis?.[notification?.message?.reactions?.[0]] + ' on your' + temp[1]}
                              </span>
                            </p>
                            <p className="user_project">
                              {' '}
                              <span>
                                {' '}
                                {notification?.message?.msg.substring(0, 75)}{' '}
                                {notification?.message?.msg?.length > 75 && '...'}
                              </span>
                            </p>
                            <div className="bottom_sec d_flex">
                              <span className="time">
                                {getDurationDate(
                                  duration2,
                                  UTCToFormattedPartnerTimeZone(
                                    notification?.createdAt,
                                    notification?.users?.partnerAdmin?.timezone ||
                                      notification?.users?.region?.timezone ||
                                      user?.timezone
                                  )
                                )}{' '}
                              </span>
                              <span className="date">
                                {
                                  checkTimeZoneDateEvents(
                                    notification?.createdAt,
                                    notification?.users?.partnerAdmin?.timezone ||
                                      notification?.users?.region?.timezone ||
                                      user?.timezone
                                  ).convertedDateforListing
                                }{' '}
                                {` (${notification?.users?.partnerAdmin?.timezone?.abbr || notification?.users?.region?.timezone?.abbr || user?.timezone?.abbr})`}
                              </span>
                            </div>
                            <div className="unread_notify d_flex_center">
                              <CustomImage src={unread_notify} />
                            </div>
                          </div>
                        </div>
                      </li>
                    )}
                    {type === 'eventApproval' && (
                      <li className={`${notification?.read ? '' : 'unread'}`} key={notification?._id}>
                        <div
                          className="notification_main d_flex align_items_center cursor_pointer"
                          onClick={() => {
                            const payload = {
                              event: 'readNotification',
                              data: { notiId: notification?._id },
                            };
                            sendEvent({ payload });
                            setState({ tab: 'Pending Approval' });
                            navigate('/events');
                          }}>
                          <div className="notify_pic">
                            <CustomImage
                              src={
                                notification?.users?.profilePic ||
                                generateCustomAvatar(
                                  `${notification?.users?.preferredFname || notification?.users?.fname} ${notification?.users?.preferredLname || notification?.users?.lname} `,
                                  'small-caps bold 75px Montserrat_ragular'
                                )
                              }
                            />
                          </div>
                          <div className="notification_inner d_flex flex_direction_column">
                            <p className="user_project">
                              {`${notification?.users?.preferredFname || notification?.users?.fname} ${notification?.users?.preferredLname || notification?.users?.lname} `}{' '}
                              <span> has created the event. Please check their approval request.</span>
                            </p>
                            <div className="bottom_sec d_flex">
                              <span className="time">
                                {getDurationDate(
                                  duration2,
                                  UTCToFormattedPartnerTimeZone(
                                    notification?.createdAt,
                                    notification?.users?.partnerAdmin?.timezone ||
                                      notification?.users?.region?.timezone ||
                                      user?.timezone
                                  )
                                )}{' '}
                              </span>
                              <span className="date">
                                {
                                  checkTimeZoneDateEvents(
                                    notification?.createdAt,
                                    notification?.users?.partnerAdmin?.timezone ||
                                      notification?.users?.region?.timezone ||
                                      user?.timezone
                                  ).convertedDateforListing
                                }{' '}
                                {` (${notification?.users?.partnerAdmin?.timezone?.abbr || notification?.users?.region?.timezone?.abbr || user?.timezone?.abbr})`}
                              </span>
                            </div>
                            <div className="unread_notify d_flex_center">
                              <CustomImage src={unread_notify} />
                            </div>
                          </div>
                        </div>
                      </li>
                    )}
                    {type === 'Message' && (
                      <li className={`${notification?.read ? '' : 'unread'}`} key={notification?._id}>
                        <div
                          className="notification_main d_flex align_items_center cursor_pointer"
                          onClick={() => {
                            const payload = {
                              event: 'readNotification',
                              data: { notiId: notification?._id },
                            };
                            sendEvent({ payload });
                            const query = {
                              firstName: notification?.users?.preferredFname || notification.users?.fname,
                              lastName: notification?.users?.preferredLname || notification.users?.lname,
                              profilePic: notification.users?.profilePic ? notification.users?.profilePic : '',
                            };
                            navigate(
                              `/team-inbox/${notification?.users?._id}?query=${encodeURIComponent(encryption(query))}`,
                              {}
                            );
                          }}>
                          <div className="notify_pic">
                            <CustomImage
                              src={
                                notification?.users?.profilePic ||
                                generateCustomAvatar(
                                  `${notification?.users?.preferredFname || notification?.users?.legaFname} ${notification?.users?.preferredLname || notification?.users?.lname} `,
                                  'small-caps bold 75px Montserrat_ragular'
                                )
                              }
                            />
                          </div>
                          <div className="notification_inner d_flex flex_direction_column">
                            <p className="user_project">
                              {`${notification?.users?.preferredFname || notification?.users?.fname} ${notification?.users?.preferredLname || notification?.users?.lname} `}{' '}
                              <span>sent you a message.</span>
                            </p>
                            <p className="user_project">
                              {' '}
                              <span>
                                {notification?.message?.msg?.substring(0, 75)}
                                {notification?.message?.msg?.length > 75 && '...'}
                              </span>
                            </p>
                            <div className="bottom_sec d_flex">
                              <span className="time">
                                {getDurationDate(
                                  duration2,
                                  UTCToFormattedPartnerTimeZone(
                                    notification?.createdAt,
                                    notification?.users?.partnerAdmin?.timezone ||
                                      notification?.users?.region?.timezone ||
                                      user?.timezone
                                  )
                                )}{' '}
                              </span>
                              <span className="date">
                                {
                                  checkTimeZoneDateEvents(
                                    notification?.createdAt,
                                    notification?.users?.partnerAdmin?.timezone ||
                                      notification?.users?.region?.timezone ||
                                      user?.timezone
                                  ).convertedDateforListing
                                }{' '}
                                {` (${notification?.users?.partnerAdmin?.timezone?.abbr || notification?.users?.region?.timezone?.abbr || user?.timezone?.abbr})`}
                              </span>
                            </div>
                            <div className="unread_notify d_flex_center">
                              <CustomImage src={unread_notify} />
                            </div>
                          </div>
                        </div>
                      </li>
                    )}
                    {type === 'CourseCompleted' && (
                      <li className={`${notification?.read ? '' : 'unread'}`} key={notification?._id}>
                        <div
                          className="notification_main d_flex align_items_center cursor_pointer"
                          onClick={() => {
                            const payload = {
                              event: 'readNotification',
                              data: { notiId: notification?._id },
                            };
                            sendEvent({ payload });
                            setContentState({ tab: 'Project' });
                            navigate('/reporting');
                          }}>
                          <div className="notify_pic">
                            <CustomImage
                              src={
                                notification?.users?.profilePic ||
                                generateCustomAvatar(
                                  `${notification?.users?.preferredFname || notification?.users?.fname} ${notification?.users?.preferredLname || notification?.users?.lname} `,
                                  'small-caps bold 75px Montserrat_ragular'
                                )
                              }
                            />
                          </div>
                          <div className="notification_inner d_flex flex_direction_column">
                            <p className="user_project">
                              {`${notification?.users?.preferredFname || notification?.users?.fname} ${notification?.users?.preferredLname || notification?.users?.lname} `}{' '}
                              <span> has completed the assigned Project.</span>
                            </p>
                            <div className="bottom_sec d_flex">
                              <span className="time">
                                {getDurationDate(
                                  duration2,
                                  UTCToFormattedPartnerTimeZone(
                                    notification?.createdAt,
                                    notification?.users?.partnerAdmin?.timezone ||
                                      notification?.users?.region?.timezone ||
                                      user?.timezone
                                  )
                                )}{' '}
                              </span>
                              <span className="date">
                                {
                                  checkTimeZoneDateEvents(
                                    notification?.createdAt,
                                    notification?.users?.partnerAdmin?.timezone ||
                                      notification?.users?.region?.timezone ||
                                      user?.timezone
                                  ).convertedDateforListing
                                }{' '}
                                {` (${notification?.users?.partnerAdmin?.timezone?.abbr || notification?.users?.region?.timezone?.abbr || user?.timezone?.abbr})`}
                              </span>
                            </div>
                            <div className="unread_notify d_flex_center">
                              <CustomImage src={unread_notify} />
                            </div>
                          </div>
                        </div>
                      </li>
                    )}
                    {type === 'InvitationApproved' && (
                      <li className={`${notification?.read ? '' : 'unread'}`} key={notification?._id}>
                        <div
                          className="notification_main d_flex align_items_center cursor_pointer"
                          onClick={() => {
                            const payload = {
                              event: 'readNotification',
                              data: { notiId: notification?._id },
                            };
                            sendEvent({ payload });
                            setState({ tab: 'All Events' });
                            navigate('/events');
                          }}>
                          <div className="notify_pic">
                            <CustomImage
                              src={
                                notification?.users?.profilePic ||
                                generateCustomAvatar(
                                  `${notification?.users?.preferredFname || notification?.users?.fname} ${notification?.users?.preferredLname || notification?.users?.lname} `,
                                  'small-caps bold 75px Montserrat_ragular'
                                )
                              }
                            />
                          </div>
                          <div className="notification_inner d_flex flex_direction_column">
                            <p className="user_project">
                              <span className="fw_400">
                                Your {notification?.users?.role === 'Mentee' ? 'mentee' : 'mentor'}
                              </span>{' '}
                              {`${notification?.users?.preferredFname || notification?.users?.fname} ${notification?.users?.preferredLname || notification?.users?.lname} `}
                              <span className=""> has accepted the event </span>
                              <span className="fw_700">{notification?.event?.event_name}</span>
                            </p>

                            <div className="bottom_sec d_flex">
                              <span className="time">
                                {getDurationDate(
                                  duration2,
                                  UTCToFormattedPartnerTimeZone(
                                    notification?.createdAt,
                                    notification?.users?.partnerAdmin?.timezone ||
                                      notification?.users?.region?.timezone ||
                                      user?.timezone
                                  )
                                )}{' '}
                              </span>
                              <span className="date">
                                {
                                  checkTimeZoneDateEvents(
                                    notification?.createdAt,
                                    notification?.users?.partnerAdmin?.timezone ||
                                      notification?.users?.region?.timezone ||
                                      user?.timezone
                                  ).convertedDateforListing
                                }{' '}
                                {` (${notification?.users?.partnerAdmin?.timezone?.abbr || notification?.users?.region?.timezone?.abbr || user?.timezone?.abbr})`}
                              </span>
                            </div>
                            <div className="unread_notify d_flex_center">
                              <CustomImage src={unread_notify} />
                            </div>
                          </div>
                        </div>
                      </li>
                    )}
                    {type === 'InvitationDeclined' && (
                      <li className={`${notification?.read ? '' : 'unread'}`} key={notification?._id}>
                        <div
                          className="notification_main d_flex align_items_center cursor_pointer"
                          onClick={() => {
                            const payload = {
                              event: 'readNotification',
                              data: { notiId: notification?._id },
                            };
                            sendEvent({ payload });
                            setState({ tab: 'All Events' });
                            navigate('/events');
                          }}>
                          <div className="notify_pic">
                            <CustomImage
                              src={
                                notification?.users?.profilePic ||
                                generateCustomAvatar(
                                  `${notification?.users?.preferredFname || notification?.users?.fname} ${notification?.users?.preferredLname || notification?.users?.lname} `,
                                  'small-caps bold 75px Montserrat_ragular'
                                )
                              }
                            />
                          </div>
                          <div className="notification_inner d_flex flex_direction_column">
                            <p className="user_project">
                              <span className="fw_400">
                                Your {notification?.users?.role === 'Mentee' ? 'mentee' : 'mentor'}
                              </span>{' '}
                              {`${notification?.users?.preferredFname || notification?.users?.fname} ${notification?.users?.preferredLname || notification?.users?.lname} `}
                              <span className=""> has declined the event </span>
                              <span className="fw_700">{notification?.event?.event_name}</span>
                            </p>

                            <div className="bottom_sec d_flex">
                              <span className="time">
                                {getDurationDate(
                                  duration2,
                                  UTCToFormattedPartnerTimeZone(
                                    notification?.createdAt,
                                    notification?.users?.partnerAdmin?.timezone ||
                                      notification?.users?.region?.timezone ||
                                      user?.timezone
                                  )
                                )}{' '}
                              </span>
                              <span className="date">
                                {
                                  checkTimeZoneDateEvents(
                                    notification?.createdAt,
                                    notification?.users?.partnerAdmin?.timezone ||
                                      notification?.users?.region?.timezone ||
                                      user?.timezone
                                  ).convertedDateforListing
                                }{' '}
                                {` (${notification?.users?.partnerAdmin?.timezone?.abbr || notification?.users?.region?.timezone?.abbr || user?.timezone?.abbr})`}
                              </span>
                            </div>
                            <div className="unread_notify d_flex_center">
                              <CustomImage src={unread_notify} />
                            </div>
                          </div>
                        </div>
                      </li>
                    )}
                  </>
                );
              })}
              {notificationResponse?.data?.length === 0 && (
                <li className="not_available">Notification not available</li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
