import { memo } from 'react';

interface SafeHydrateProps {
  children: React.ReactNode;
}

const SafeHydrate = ({ children }: SafeHydrateProps): JSX.Element => (
  <div suppressHydrationWarning>{typeof document === 'undefined' ? null : children}</div>
);

export default memo(SafeHydrate);
