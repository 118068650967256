import { AdminUserData } from 'types';
import { create } from 'zustand';

interface Sort {
  headerType: string;
  sortType: string;
}

interface optionsProps {
  label: string,
  value: string
}

export const initialAdminUsersState = {
  search: '',
  passwordError:'',
  apiRecall:false,
  adminType: { label: '', value: '' },
  status: [],
  regions: [],
  sort: {
    headerType: '',
    sortType: ''
  },
  selectAll: false,
  multiSelectArray: [],
  deleteUser: null,
  deleteUsers: null,
  resendInviteUser: null,
  resendInviteUsers: null,
  filterModal: false,
  pagination: {
    limit: 50,
    page: 1,
    pages: 0,
    total: 0
  },
  csvPopup: false,
  csv: null,
  skippedUser: {
    csvUrl: '',
    skippedUser: null,
    skippedUserCount: -1,
    uplaodedUser: -1,
  },
  settingPopup: false,
  deleteRestrictionPopup: null,
  isChangePermission:false,
  adminId:null,
  userName:'',
  permissionRestrictionPopup:null,
  changePermissionLoader:false,
  adminRole:null,
  partnerOrRegion:[],
  isChangeRole:false,
  partnerId:'',
  partnerOrRegionObj:null,
  failedToMovePartnerObj:null,
  isMovePartner:false,
  partnerName:null,
  partnerRole:null,
  addClass:null,
  partnerOrRegionId:'',
  userProfilePic:'',
  profilePic:'',
  isProfleUpload:false,
  mentorOrMenteeMessageList: null,
  mentorOrMenteeMessageLoader: false
}

interface useAdminUsersStore {

  search: string;
  adminType: optionsProps;
  status: optionsProps[];
  regions: optionsProps[];
  sort: Sort;
  selectAll: boolean;
  multiSelectArray: String[];
  deleteUser: AdminUserData | null;
  deleteUsers: AdminUserData[] | null;
  resendInviteUser: AdminUserData | null;
  resendInviteUsers: AdminUserData[] | null;
  filterModal: boolean;
  pagination: any;
  csvPopup: boolean;
  passwordError:string;
  csv: any;
  skippedUser: {
    csvUrl: string;
    skippedUser: any;
    skippedUserCount: number;
    uplaodedUser: number;
  };
  settingPopup: boolean;
  deleteRestrictionPopup: any;
  adminId:string|null;
  userName:string;
  permissionRestrictionPopup:any;
  changePermissionLoader:boolean;
  adminRole:any
  partnerOrRegion:any,
  isChangePermission: boolean,
  isMovePartner: boolean,
  isChangeRole:boolean,
  partnerId:string,
  partnerOrRegionObj:any,
  failedToMovePartnerObj:any,
  apiRecall:boolean,
  partnerName:any,
  partnerRole:any,
  addClass:any,

  userProfilePic:string,
  partnerOrRegionId:string,
  profilePic:string,
  isProfleUpload:boolean,

  mentorOrMenteeMessageList: String[] | null;
  mentorOrMenteeMessageLoader: Boolean;

  setSearch(search: string): void;
  setAdminType(adminType: optionsProps): void;
  setStatus(status: optionsProps[]): void;
  setRegions(regions: optionsProps[]): void;
  setAllSelect(selectAll: boolean): void;
  setMultiSelect(multiSelectArray: String[]): void;
  setSortType(headerType: string, sortType: string): void;
  setDeleteUser(deleteUser: AdminUserData | null): void;
  setDeleteUsers(deleteUsers: AdminUserData[] | null): void;
  setResendInviteUser(resendInviteUser: AdminUserData | null): void;
  setResendInviteUsers(resendInviteUser: AdminUserData[] | null): void;
  setFilterModal(): void;
  setPagination(pagination: any): void;
  setClearFilters(): void;
  setCsvPopup(): void
  setCsvFile(file: any): void;
  setSkippedUser(skippedUser: any): void;

  setState(nextState: Partial<useAdminUsersStore>): void;
  setMentorOrMenteeMessageList(mentorOrMenteeMessageList: any): void;
  setMentorOrMenteeMessageLoader(mentorOrMenteeMessageLoader: boolean): void;

}

const useAdminUsers = create<useAdminUsersStore>((set) => ({

  ...initialAdminUsersState,

  setSearch: (search) => set({ search }),
  setAdminType: (adminType) => set({ adminType }),
  setStatus: (status) => set({ status }),
  setRegions: (regions) => set({ regions }),
  setAllSelect: (selectAll) => set({ selectAll }),
  setMultiSelect: (multiSelectArray) => set({ multiSelectArray }),
  setSortType: (headerType, sortType) => set({ sort: { headerType, sortType } }),
  setDeleteUser: (deleteUser) => set({ deleteUser }),
  setDeleteUsers: (deleteUsers) => set({ deleteUsers }),
  setResendInviteUser: (resendInviteUser) => set({ resendInviteUser }),
  setResendInviteUsers: (resendInviteUsers) => set({ resendInviteUsers }),
  setFilterModal: () => set(({ filterModal }) => ({ filterModal: !filterModal })),
  setPagination: (pagination) => set(() => ({ pagination })),
  setClearFilters: () => set(() => ({  adminType: { label: '', value: '' }, status: [], regions: [] })),
  setCsvPopup: () => set(({ csvPopup }) => ({ csvPopup: !csvPopup })),
  setCsvFile: (file) => set(() => ({ csv: file })),
  setSkippedUser: (skippedUser) => set(() => ({ skippedUser })),

  setState: (nextState) => set(() => ({ ...nextState })),
  setMentorOrMenteeMessageList: (mentorOrMenteeMessageList) => set(() => ({ mentorOrMenteeMessageList })),
  setMentorOrMenteeMessageLoader: (mentorOrMenteeMessageLoader) => set(() => ({mentorOrMenteeMessageLoader})),
}));


export default useAdminUsers;
