import { loader } from 'utils/images';

const Loader = () => {
  return (
    <div className="loader_bg">
      <div className="loader">
        <img src={loader} alt="" />
      </div>
    </div>
  );
};

export default Loader;
