import { memo } from 'react';
import { RouterProvider, createBrowserRouter, Route, createRoutesFromElements } from 'react-router-dom';
import UnAuthorized from 'views/unauthorized/UnAuthorized';
import { privateRouteList, publicRouteList } from '.';
import PrivateRouter from './PrivateRouter';
import PublicRouter from './PublicRouter';
import { cookieDecryption } from '../../utils';
import { validateRedirectURL } from 'utils/validateRedirectURL';

function RoutesComponent() {
  const urlParams = new URLSearchParams(window.location.search);
  const redirectUrl = urlParams.get('redirect');
  const user = cookieDecryption('user');

  if (redirectUrl && user && validateRedirectURL(redirectUrl)) {
    window.location.replace(redirectUrl);
  }

  const render = () => {
    return (
      <>
        {privateRouteList.map(({ title, path, allowedRoles, element: Element, isHideLayout }) => (
          <Route element={<PrivateRouter allowedRoles={allowedRoles} title={title} isHideLayout={!!isHideLayout} />}>
            <Route path={path} element={<Element />} />
          </Route>
        ))}

        {publicRouteList.map(({ title, path, element: Element }) => (
          <Route element={<PublicRouter title={title} />}>
            <Route path={path} element={<Element />} />
          </Route>
        ))}

        <Route path={'/*'} element={<UnAuthorized />} />
      </>
    );
  };

  const router = createBrowserRouter(createRoutesFromElements(render()));

  return <RouterProvider router={router} />;
}

export default memo(RoutesComponent);
