import {useState} from 'react';
import axiosClient from "api/axiosClient";
import useToaster from 'hooks/store/toast/useToaster';
import { useNavigate } from "react-router-dom";
import useAdminUsers from 'hooks/store/admin-users/useAdminUsers';

interface changePasswordrReq {
    userId: string;
    password: string;
    confirmPassword: string;
}

export default function useUpdatePassword() {
    const [response, setResponse] = useState(null);
    const { settingPopup, setState  } = useAdminUsers();

    const toaster = useToaster();
    const navigate = useNavigate();

    const changePassword = async (req: changePasswordrReq) => {
   
        try {
            const data = await axiosClient.post('/admin/changePassword', req).then((res) => res.data);
            if (!data?.error) {
                setResponse(data);
                toaster.showToast('Success',data.msg);
                setState({settingPopup : false})
                return;
            }
            toaster.showToast('Danger',data.msg);
        } catch (error : any) {
            setState({passwordError:error.response.data.msg})
        }finally {
        }

    }

    const reset = () => {
        setResponse(null);
    };

    const redirect = () => navigate('/');

    return {
        response,
        redirect,
        changePassword,
        reset
    }
}
